import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";

import AppliedFilters from "../../components/AppliedFilters";
import FilterByDropdown from "../../components/FilterByDropdown";
import NewBanner from "../../components/NewBanner";
import { DimensionsDropdowns, Loader } from "../../form/elements";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import SwitchToggle from "../../newComponents/SwitchToggle";
import FilterDialog from "../../sections/filter/FilterDialog";
import { ReportHeader } from "../ReportHeader";

import {
  ALL_REPORTS,
  ALL_WIDGETS,
  BUTTON_DROPDOWN_VARIANT,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CURRENT_FILTER_COOKIE,
  DIALOG_SIZE,
  FILTER,
  FY_VALUES,
  HEADER_ELEMENT,
  MENU_ITEM,
  SAVED_FILTER_ID_COOKIE,
  SCENARIO_STATUS,
  SIZES,
} from "../../class/constants";
import { lang } from "../../language/messages_en";
import "../styles/reportBody.css";

import {
    formatAdvancedFilter,
    formatBasicFilter,
    getLocalStorageValueByParameter,
    logout,
    setLocalStorageValueByParameter,
    updateWindowLoading,
} from "../../class/common";
import { generateQuarter, getPeriodDifference, getPeriodFromDate } from "../../class/date";
import { saveCookie } from "../../class/jqueries";
import {
  areObjectsNotEmpty,
  checkIfMonthsValid,
  copyObjectValues,
  findOptionByKey,
  findOptionByKeyValue,
  getSectionExists,
  tryParse,
} from "../../class/utils";
import HeatMapConfigure from "../../components/secondDimension/HeatMapConfigure";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import { getNewEntityFilter } from "../../sections/filter/FilterHelperFunctions";
import Heatmap from "../../sections/multipleDimensions/Heatmap";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { updateDatasetState, updatePeriodsStatusState, updatePsViewsState, updateVectorState } from "../../actions/scenariosActions";
import { getClientPeriods, getCustomViewData, getDatasets, getFilterEntities, getPeriodsStatus, getVectors } from "../api/api";
import { useAuthenticated, useCalendarToggle } from "../CustomHooks";
import { renderButton, renderPeriodCalendar, renderTitle, tablesToExcel } from "../functions/componentFunctions";
import { addCorrespondingParenthesisToFilter } from "../functions/filterFunctions";
import { validatePeriodRange } from "../functions/periodFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { useMemo } from "react";

/**
 * @author Ralph HaydarBejjani
 * @description component renders title  , filter,period calendar , 2 vectors dropdown and  Apply button,  configure dropdown  with  {@link Heatmap} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link Heatmap}
 */
const HeatmapWrapper = (props) => {
  /**Store */
  const profiles = useSelector(state=>state.profiles);
  const stateProfiles = useSelector(state=>state.stateProfiles);
  const customGroups = useSelector(state=>state.customGroups);
  const profileColumns = useSelector(state=>state.profileColumns);
  const columnProfiles = useSelector(state=>state.columnProfiles);
  const psLinesOptions = useSelector((state) => state.psLinesOptions);
  const quadrantTierOptions = useSelector((state) => state.quadrantTierOptions);

  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;

  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const dimensionDropdownsRef = useRef();
  const reportRef = useRef();

  const appliedFiltersRef = useRef();
  const filterDialRef = useRef();
  const filterByRef = useRef();
  const configDropdown = useRef();
  const countRef = useRef(0);
  const manageColsOverlay = useRef();
  const manageColsDrillOverlay = useRef();
  const filterSet = useRef(false);

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar } = useCalendarToggle();

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [vectorState, setVectorState] = useState({});
  const [datasetState, setDatasetState] = useState({});
  const [PSViewsState, setPSViewsState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);

  /**Filter Related States */
  const [filterFinal, setFilterFinal] = useState(
    props.history.location.state
      ? props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || []
        : []
      : []
  );
  const [filterFinalBasic, setFilterFinalBasic] = useState(
    props.history.location.state
      ? props.history.location.state.mainFilterFinalBasic
        ? tryParse(props.history.location.state.mainFilterFinalBasic) || []
        : []
      : []
  );
  const [basicFilterEntities, setBasicFilterEntities] = useState([]);
  const [exportQueryFilter, setExportQueryFilter] = useState("");
  const [displayFilter, setDisplayFilter] = useState();
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
  const [tempFilter, setTempFilter] = useState();
  const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
  const [count, setCount] = useState(0);
  const [basicFilterItem, setBasicFilterItem] = useState();
  const [drillFilter, setDrillFilter] = useState();

  /**Header Related States */
  const profitFormat = ALL_REPORTS.HEATMAP;
  const reportTitle = ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.HEATMAP;
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0);
  const [headerElements, setHeaderElements] = useState([]);
  const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
  const [limitAccessMsg, setLimitAccessMsg] = useState("");
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [callGo, setCallGo] = useState(false);
  const [isDrilling, setIsDrilling] = useState(false);
  const [isListFullScreen, setIsListFullScreen] = useState(false);
  const [openInfoDialog, setOpenInfoDilog] = useState();
  const [infoDialogMsg, setInfoDialogMsg] = useState();
  const [openFilterDialog, setOpenFilterDialog] = useState();
  const [dataLength, setDataLength] = useState(0);
  const [profitStackLines, setProfitStackLines] = useState([]);

  const manageColumnsProps = {
    manageColumnsUpdateSections: [
      ALL_WIDGETS.FIELDS.LIST,
      MENU_ITEM.FIELDS.HEATMAP,
      MENU_ITEM.FIELDS.EROSION_AND_GROWTH,
      ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS,
    ],
  };
  const manageColsAccess = {
    [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS),
      [userAllowedMenuLinks]
    ),
    [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS),
      [userAllowedMenuLinks]
    ),
    [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS),
      [userAllowedMenuLinks]
    ),
    [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS),
      [userAllowedMenuLinks]
    ),
  };

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (scenarioState) {
      //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
      setPeriodsStatusState({});
      setVectorState({});
      setDatasetState({});
      setShowGreyOverLay(true);
      getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
      getVectors(
        scenarioState,
        dispatch,
        updateVectorState,
        setVectorState,
        reportTitle,
        profitFormat,
        props,
        false,
        filterFinal,
        userSettings,
        vectorState,
        filterDialRef,
        ALL_REPORTS.HEATMAP
      );
      getDatasets(scenarioState, dispatch, updateDatasetState, setDatasetState, props, profitFormat, params, false, userSettings, datasetState);
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [scenarioState]);

  useEffect(() => {
    if (
      Object.keys(periodsStatusState).length > 0 &&
      Object.keys(scenarioState).length > 0 &&
      Object.keys(vectorState).length > 0 &&
      Object.keys(PSViewsState).length > 0 &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [periodsStatusState, vectorState, PSViewsState, showGreyOverLay, isDrilling, profitStackLines, isListFullScreen]);

  useEffect(() => {
    if (vectorState) {
      getCustomViewData(
        PSViewsState,
        vectorState,
        params,
        filterFinal,
        userSettings,
        scenarioState,
        dispatch,
        updatePsViewsState,
        setPSViewsState,
        false,
        props
      );
    }
  }, [vectorState]);

  useEffect(() => {
    if (
      Object.keys(periodsStatusState).length > 0 &&
      Object.keys(scenarioState).length > 0 &&
      Object.keys(vectorState).length > 0 &&
      Object.keys(PSViewsState).length > 0 &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [count, basicFilterEntities, filterFinalBasic, headerChangedCounter]);

  useEffect(() => {
    if (callGo && !showGreyOverLay) {
      apply();
      setCallGo(false);
    }
  }, [callGo, filterFinal, filterFinalBasic]);

  /**Limit Access Related Functions */
  const checkForLimitAccessMessage = (data, showModal) => {
    let noAccessMessage = data.no_access_message;
    if (!!noAccessMessage && showModal && !props.checkedPsLines) {
      setLimitAccessDialogOpen(true, noAccessMessage);
    }
    return !!noAccessMessage;
  };

  const setLimitAccessDialogOpen = (isOpen, msg) => {
    setLimitAccessMsg(msg);
    setOpenLimitAccessDialog(isOpen);
  };

  /**Filter Related Functions */
  const getExportQueryFilter = (additionalFilter) => {
    if(typeof additionalFilter === "string") {
      additionalFilter = tryParse(additionalFilter)
    }
    if (additionalFilter?.filter && typeof additionalFilter?.filter != "function") {
      additionalFilter = additionalFilter.filter;
    }
    return additionalFilter
      ? additionalFilter.find((f) => !f.isBasicFilter)
        ? formatAdvancedFilter(
            additionalFilter,
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          )
        : formatBasicFilter(additionalFilter, userSettings?.user?.user_allowed_vectors)
      : exportQueryFilter;
  };

  const switchBasicToAdvanced = () => {
    if (filterByRef?.current) {
      filterByRef.current.handleClose();
    }

    if(!filterFinal) {
      return;
    }
    
    let filterStr = filterFinal;
    let filter =
      typeof filterStr === "string"
        ? JSON.parse(filterStr)
        : filterStr.filter && typeof filterStr.filter !== "function"
        ? filterStr.filter
        : filterStr;

    filter = addCorrespondingParenthesisToFilter(filter);
    if (filter.find((e) => e.isBasicFilter)) {
      filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
    }
  };

  const onChangeFilter = (filterStr, afterDrilling, applyFilter = false, isClear, isSwitchBasic, filterDisplayName) => {
    let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : filterStr;
    // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
    if (
      !filterDisplayName &&
      (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null ||
        !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) ||
        getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined")
    ) {
      filter.map((item) => {
        item.filter_display_name = undefined;
      });
    }

    if (isSwitchBasic) {
      filter.map(function (item) {
        item.isBasicFilter = false;
      });
    }
    let tempState = {};

    tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if (isClear) {
      filter = "[]";
      tempState.filterFinalBasic = [];
    } else if (!filter.find((f) => !f.isBasicFilter)) {
      tempState.filterFinalBasic = copyObjectValues(filter).filter((e) => e.field === "number"); //from selecting a saved basic filter
      let filterObjs = copyObjectValues(filter);
      const unique = [...new Set(filterObjs.map((item) => item.vector))]; // [ 'A', 'B']
      for (var e in unique) {
        getFilterEntities(
          { value: unique[e] },
          vectorState,
          setBasicFilterEntities,
          basicFilterEntitiesState,
          profitFormat,
          userSettings,
          scenarioState,
          tempState.filterFinal,
          getCountRef,
          setCount,
          setCountRef
        );
      }
    } else if (applyFilter) {
      // from apply in advanced
      tempState.filterFinalBasic = [];
    }
    tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if (!applyFilter) {
      let tempState2 = { tempFilter: filterStr }; //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
      if (!isSwitchBasic) {
        tempState2.filterFinalBasic = tempState.filterFinalBasic;
        setFilterFinalBasic(tempState2.filterFinalBasic);
      }
      setTempFilter(filterStr);
      return;
    }

    //check if filter contains quadrant or quadrant/tier filter
    tempState.isAdvancedFilterApplied = isClear ? false : true;
    tempState.exportQueryFilter =
      filterStr === "[]"
        ? undefined
        : formatAdvancedFilter(
            JSON.parse(filterStr),
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          );

    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);
    setExportQueryFilter(tempState.exportQueryFilter);
    // setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
    setTempFilter(tempState.tempFilter);

    if (isClear) {
      filterStr = "[]";
    }
    if(history && history.location && history.location.state ){
      history.push({
        state: {
          outsideFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          mainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          filter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          filterFinal: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          originalMainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          scenarioState: history?.location?.state?.scenarioState
        }
      })
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
    }
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const setCountRef = (count) => {
    countRef.current = count;
  };

  const getCountRef = () => {
    return countRef.current;
  };

  const getFilterBySectionsData = () => {
    let isClearBtnDisabled = false;
    let filterFinalBasicState = filterFinalBasic || [];
    let vectors = vectorState.vectorOptions?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle); // remove vectors with entityCount <= threshold and that are not group title;
    if (vectors && filterFinalBasicState) {
      filterFinalBasicState = typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
      filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
      isClearBtnDisabled = filterFinalBasicState.length === 0;
      vectors = vectors.map((item) => ({
        ...item,
        checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value), // .some() checks if any of the element.vector === item.value
      }));
    }

    // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
    let sectionsJson = [];
    let quarters = [];
    var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
    var endQuarter = datasetState.nextEndQuarter?.split("Q")[1];
    var startYear = datasetState.nextStartQuarter?.split("Q")[0];
    var endYear = datasetState.nextEndQuarter?.split("Q")[0];
    quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
    vectors = vectors; // add isDisabled to vectors that are not generated

    let suggestedVectors = vectors?.filter((f) => vectorState.nextVectors?.includes(f.value) && !f.isGroupTitle && !f.isDisabled); // get nextVectors as jsonObject
    suggestedVectors = suggestedVectors?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold); // checks if header vectors' entity count <= thereshold
    let hasSuggested = suggestedVectors?.length > 0;
    if (hasSuggested) {
      sectionsJson.push({
        sectionTitle: lang.suggested,
        itemsList: suggestedVectors,
      });
    }

    sectionsJson.push({
      sectionTitle: lang.all,
      itemsList: vectors,
    });
    return { data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled };
  };

  const handleCheckBoxChange = (item) => {
    let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
    let isAdvancedFilterApplied =
      filterFinalArr &&
      filterFinalArr !== "[]" &&
      (typeof filterFinalArr === "object" ? filterFinalArr.find((e) => !e.isBasicFilter) : JSON.parse(filterFinalArr).find((e) => !e.isBasicFilter));
    if (isAdvancedFilterApplied) {
      setBasicFilterItem(item);
      openFilterModal();
    } else {
      handleBasicFilterCheckBoxChange(item);
    }
  };

  const filterOnBasicFilter = (basicAppliedFilter) => {
    let filters = copyObjectValues(basicAppliedFilter.filter((e) => e.entities.length));
    filters?.forEach((e) => {
      e.isBasicFilter = true;
      e.filter_display_name = undefined;
      e.entities = e.entities.map((ent) => {
        ent.value = ent.number;
        ent.label = ent.number;
        return ent;
      });
    });
    let filtersByName = copyObjectValues(filters);
    filtersByName?.forEach((e) => {
      e.field = "name";
      e.entities = e.entities.map((ent) => {
        ent.value = ent.name;
        ent.label = ent.name;
        return ent;
      });
      filters.push(e);
    });

    if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    let filterFinalBasic = copyObjectValues(basicAppliedFilter); //typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }
    filterFinalBasic?.forEach((e) => {
      e.filter_display_name = undefined;
    });

    filters = addCorrespondingParenthesisToFilter(filters);
    if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    setFilterFinal(JSON.stringify(filters));
    setFilterFinalBasic(filterFinalBasic);
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const handleBasicFilterCheckBoxChange = (item, clear, entity, allEntities) => {
    let tempState = {};
    let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
    let initialFilter = copyObjectValues(filter);

    filter = filter || [];
    let removedLine = [];
    let isClear = false;
    let isUnCheck = false;
    if (entity && item) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          if (entity.checked === true) {
            filter[e].entities.push(entity);
          } else {
            filter[e].entities = filter[e].entities.filter((f) => f.value !== entity.value);
          }
        }
      }
    } else if (item && allEntities) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          for (let j in allEntities) {
            filter[e].entities.push(allEntities[j]);
          }
        }
      }
    } else if (item) {
      if (filter?.find((e) => e.vector === item.value)) {
        removedLine = filter.find((e) => e.vector === item.value);
        filter = filter.filter((e) => e.vector !== item.value);
        delete basicFilterEntities[item.value];
        isUnCheck = true;
      } else {
        let selectedEmptyFilter = getNewEntityFilter(
          item.value,
          FILTER.VALUES.FIELD.NUMBER,
          [],
          FILTER.VALUES.FILTER_ROW_TYPE.FILTER,
          lang.ui_filter.dropdowns.and
        );
        selectedEmptyFilter.vectorLabel = item.label;
        filter?.push(selectedEmptyFilter);
      }
    } else if (clear) {
      filter = [];
      tempState.filterFinal = [];
      isClear = true;
      if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(tempState.exportQueryFilter);
      }
    }
    filter = addCorrespondingParenthesisToFilter(filter);
    if (isUnCheck && removedLine.entities.length > 0) {
      tempState.filterFinalBasic = filter;
      let copyFilter = copyObjectValues(filter);
      copyFilter.forEach((e) => {
        if (e.entities) {
          e.isBasicFilter = true;
          e.entities = e.entities.map((ent) => {
            ent.value = ent.number;
            ent.label = ent.number;
            return ent;
          });
        }
      });
      let filtersByName = copyObjectValues(copyFilter);
      filtersByName.forEach((e) => {
        e.field = "name";
        e.entities = e.entities.map((ent) => {
          ent.value = ent.name;
          ent.label = ent.name;
          return ent;
        });
        copyFilter.push(e);
      });
      tempState.filterFinal = JSON.stringify(copyFilter);
      tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors);
    }
    filter.map((item) => {
      item.filter_display_name = undefined;
    });
    tempState.filterFinalBasic = isUnCheck ? JSON.stringify(filter) : filter;
    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);

    if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
      basicFilterEntities[item.value].map(entity => entity.checked = false); 
    } 
    
    let countState = countRef.current;
    setCount(countState + 1);
    countRef.current = countState + 1;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }

    if (isUnCheck && removedLine.entities.length > 0 && !showGreyOverLay) {
      if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
      }
      let countstate = countRef.current;
      setCount(countstate + 1);
      countRef.current = countState + 1;
      // apply();
      setCallGo(true);
    } else if (!isClear) {
      getFilterEntities(
        item,
        vectorState,
        setBasicFilterEntities,
        basicFilterEntitiesState,
        profitFormat,
        userSettings,
        scenarioState,
        tempState.filterFinal,
        getCountRef,
        setCount,
        setCountRef
      );
    }
  };

  const clearClick = () => {
    handleBasicFilterCheckBoxChange(undefined, true);
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const openFilterModal = () => {
    setOpenFilterDialog(true);
  };

  const closeFilterModal = () => {
    setOpenFilterDialog(false);
  };

  const filterModalBody = () => {
    return (
      <h4>
        {lang.clear_advanced_filter}
        <br />
        {lang.switching_to_quick_filter}
      </h4>
    );
  };

  const handleBasicFilterAndClose = () => {
    closeFilterModal();
    clearAdvancedFilter(basicFilterItem);
  };

  const clearAdvancedFilter = (basicFilterItem) => {
    filterDialRef?.current?.clearFilter();
    setFilterFinal([]);
    handleBasicFilterCheckBoxChange(basicFilterItem);
  };

  const saveDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.clear_filter}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={handleBasicFilterAndClose}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeFilterModal}
        />
      </>
    );
  };


  const setInfoDialogOpen = (isOpen, infoMsg) => {
    setOpenInfoDilog(isOpen);
    setInfoDialogMsg(infoMsg);
  };

  const runApplyValidations = () => {
    if (getPeriodDifference(getPeriodFromDate(periodsStatusState.customStartDate), getPeriodFromDate(periodsStatusState.customEndDate)) > 12) {
      setInfoDialogOpen(true, lang.not_allowed_periods_selection);
      return false;
    }
    return true;
  };

  const apply = () => {
    if (!runApplyValidations()) {
      return;
    }
    if (showGreyOverLay) {
      setIsDrilling(false);
    }
    reportRef?.current.go(ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.HEATMAP, true);
    setShowGreyOverLay(false);
  };

  const getNextVectors = () => {
   

    return vectorState.nextVectors;
  };

  const configApplyClick = (obj) => {
    configDropdown.current.setConfigDropdownOpen(false);
    reportRef?.current?.onHeatmapApplyConfigure(obj);
  };

  const renderConfigureBody = () => {
    return (
      <div id="configure-dropdown">
        <div id="heatmap_configure_dialog" className={"heatmap-configure table-configure-dialog configure-container configure_body "}>
          <HeatMapConfigure
            primaryBtnLabel={"Apply"}
            onPrimaryClick={configApplyClick}
            defaultConfObj={reportRef?.current?.state.tempHeatmapConfObj}
            setTempConfObj={reportRef?.current?.setTempHeatmapConfObj}
            stacksData={PSViewsState?.profitStackViews}
            defaultPslData={profitStackLines}
            defaultStack={
              findOptionByKey(PSViewsState.profitStackViews, reportRef?.current?.state.tempHeatmapConfObj?.stack) ||
              findOptionByKey(PSViewsState.profitStackViews, 0)
            }
            onStackChange={(stack) => {
              reportRef?.current?.getProfitStackLines(false, stack, true);
            }}
          />
        </div>
      </div>
    );
  };

  const getHeaderElements = () => {
    let headerElements = [];
    let sectionsData = getFilterBySectionsData();
    let nextVectors = getNextVectors();
    let tooltip =  isDrilling ? lang.exit_drill_to_proceed : "";
    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(reportTitle)}
          {renderPeriodCalendar(
            periodsStatusState,
            handleElementChange,
            undefined,
            clientPeriodsState.allPeriods,
            toggleCalendar,
            isDrilling,
            scenarioState,
            false,
            tooltip
          )}
        <div key={Math.random()} uk-tooltip={isDrilling ? lang.exit_drill_to_proceed : "title:"} className={"uk-flex uk-flex-column uk-flex-start"}>
          <div key={Math.random()}>
            <DimensionsDropdowns
              ref={dimensionDropdownsRef}
              disabled={false}
              numberOfDimensions={2}
              dimensionOptions={vectorState.vectorOptions}
              vectorDisabled={isDrilling}
              excludeOptions={true}
              onChangeDimension={(e, index) => handleElementChange(HEADER_ELEMENT.VECTOR, e, index)}
              shouldFetchData={false}
              selectedDimensions={nextVectors}
              startAsEmpty={false}
              dropdownTooltip={isDrilling ? lang.exit_drill_to_proceed : undefined}
              hideLabel={true}
            />
          </div>
        </div>

        {<div id="apply_div">
          {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay || isDrilling)}
        </div>
        }
      </div>
    );

    headerElements.push(
      <div
        className="second_header_row gap_between_buttons"
        style={{ display: "flex", width: "100%", padding: "0.41667vw 0", justifyContent: "space-between" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "0.42vw" }}
          uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}
        >
          <div style={{ display: "flex", flexDirection: "row", columnGap: "0.42vw" }} className={isDrilling ? "disabled" : ""}>
            <FilterByDropdown
              ref={filterByRef}
              sectionsData={copyObjectValues(sectionsData.data)}
              clearClick={clearClick}
              isClearBtnDisabled={sectionsData.isClearBtnDisabled}
              handleCheckboxChange={handleCheckBoxChange}
              basicFilterCountLimit={userSettings.basicFilterCountLimit}
              switchBasicToAdvanced={switchBasicToAdvanced}
            />
            <FilterDialog
              id={"secondary_header_" + "erosion"}
              ref={filterDialRef}
              section_id={params.sectionId}
              vectorOptions={vectorState.vectorOptions}
              profitFormat={profitFormat}
              mustHideFilter={false}
              categoryToolkit={false}
              onChangeFilter={onChangeFilter}
              quadTierIsAllowed={true}
              isTempScenario={false}
              scenario_id={scenarioState.nextScenarios[0]}
              mustFetchDatasets={false}
              user={userSettings.user}
              isDrilling={false}
              fromOptions={datasetState.datasetOptions}
              datasetOptions_id={datasetState.datasetOptions_id}
              dataSet={datasetState.dataset}
              useCookies={true}
              parentMessage={""}
              psFilterDisabled={false}
              quadrantsDisabled={false}
              quadrantDefault={false}
              checkIfMonthsValid={checkIfMonthsValid}
              comparisonScenarioId={scenarioState.nextScenarios[1]}
              filterDisplayEditable={true}
              psLinesOptions={psLinesOptions}
              quadrantTierOptions={quadrantTierOptions}
              dispatch={dispatch}
              savedFilterDataState={filterDialRef?.current?.state?.savedFilterData}
              isRedirectionFromStacks={true}
              savedFilter={true}
              fromSecondaryHeader={true}
              setFilterTo={setFilterTo}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {(!isDrilling && !isListFullScreen) && (
            <ButtonDropdown
              id="heatmap"
              ref={configDropdown}
              placeholder={lang.heatmap_configure.configure}
              className="heatmap-configure-btn "
              dropId={"heatmap_configure_dialog"}
              variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
              size={SIZES.SMALL}
              firstAttr={`#button-drop-heatmap`}
              secondAttr={"#heatmap_configure_dialog"}
              renderContainerBody={renderConfigureBody}
            />
          )}
        </div>
      </div>
    );

    headerElements.push(
      <div className="third_row_header" style={{ display: "flex", width: "100%" }}>
        <div
          key={Math.random()}
          className={
            "noprint " +
            (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]"))
              ? "border-third-header "
              : "") +
            "uk-display-block"
          }
        >
          <AppliedFilters
            filterProps={filterFinalBasic}
            filterFinal={filterFinal}
            ref={appliedFiltersRef}
            userAllowedVectors={userSettings.user?.user_allowed_vectors}
            basicFilterCountLimit={userSettings.basicFilterCountLimit}
            handleCheckboxChange={handleBasicFilterCheckBoxChange}
            sectionsData={basicFilterEntities}
            fetchData={filterOnBasicFilter}
            clearFilter={filterDialRef?.current?.clearFilter}
            vectors={vectorState.vectorOptions}
            quadrantsDisabled={false}
            psFilterDisabled={false}
            parentMessage={"" /*props.filterWarningMessage*/}
            saveFilter={filterDialRef?.current?.startSaveFilter}
            clearBasicFilter={clearClick}
            clearFilterFromDialog={filterDialRef?.current?.clearFilter}
            forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
            className={isFilterDisabled ? "uk-disabled disabled" : ""}
            tooltip={"title:" + "" + ";pos: top-left"}
            filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
          />
        </div>
      </div>
    );

    return headerElements;
  };

  const handleElementChange = (name, e, dimIndex = 0) => {
    switch (name) {
      case HEADER_ELEMENT.SELECTION_RANGE: {
        let selectedYears = e.filter((year) => year.isSelected);
        let tempState = copyObjectValues(periodsStatusState);
        if (selectedYears.length > 0) {
          let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : "";
          let sortedSelectedYears = selectedYears.map((p) => p.year + p.value).sort();
          let startPeriod = sortedSelectedYears[0];
          let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
          let startDate = new Date(periods.filter((e) => e.value === startPeriod)[0].start_date);
          let endDate = new Date(periods.filter((e) => e.value === endPeriod)[0].end_date);

          if (!validatePeriodRange(startDate, endDate)) {
            setInfoDialogOpen(true, lang.insufficient_periods_selection);
            return;
          }
          tempState.nextCustomStartDate = startDate;
          tempState.nextCustomEndDate = endDate;
          tempState.customStartDate = startDate;
          tempState.customEndDate = endDate;

          saveCookie("nextCustomStartDate", startDate);
          saveCookie("nextCustomEndDate", endDate);

          setPeriodsStatusState(tempState);
          setShowGreyOverLay(true);
        }
        break;
      }
      case HEADER_ELEMENT.VECTOR: {
        var vectorValue = typeof e === "object" ? e.value : e;
        let tempState = copyObjectValues(vectorState);

        saveCookie(HEADER_ELEMENT.VECTOR, vectorValue);
        saveCookie("nextCustomStartDate", periodsStatusState.nextCustomStartDate);
        saveCookie("nextCustomEndDate", periodsStatusState.nextCustomEndDate);
        
        tempState.nextVectors = copyObjectValues(vectorState.nextVectors);
        tempState.nextVectors[dimIndex] = vectorValue;
        tempState.vectors = copyObjectValues(vectorState.nextVectors);
        tempState.vectors[dimIndex] = vectorValue;
        tempState.vectorObjects = tempState.vectors.map((v) => {
          return findOptionByKeyValue(tempState.vectorOptions, "value", v);
        });

        setVectorState(tempState);
        setShowGreyOverLay(true);
        break;
      }
      default:
        break;
    }
    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState + 1);
  };

  const setFilterTo = () => {
    let filter = filterFinal;
    //before saving the filter, save the old value if not previously set
    if(filterFinal && typeof filterFinal !== 'string') {
        filter = JSON.stringify(filterFinal);        //onChangeSavedFilter expects a string and not an array
    }
    let filterSetRef = filterSet.current; 
    if (!filterSetRef && filterFinal?.length > 0) {
      filterSet.current = true;
      filterDialRef?.current?.onChangeSavedFilter(filter, false);
    }
  }

  const tablesToExcelWrapper = () => {
    tablesToExcel(reportTitle, userSettings, vectorState, datasetState, scenarioState, reportRef, getExportQueryFilter);
  };

  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";

  let showSkeleton = !(
    Object.keys(periodsStatusState).length > 0 &&
    Object.keys(vectorState).length > 0 &&
    Object.keys(scenarioState).length > 0 &&
    Object.keys(datasetState).length > 0 &&
    headerElements.length > 0
  );

  let areStatesNotEmpty = areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]);

  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton])

  return (
    <Suspense fallback={<p id="loading">Loading...</p>}>
      {showSkeleton && <LoaderSkeleton />}
      <Modal
        id={"header-info-dialog"}
        openDialog={openInfoDialog}
        bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
        dialogActions={() => (
          <Button
            label={lang.modal.buttons.ok}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => setInfoDialogOpen(false, "")}
          />
        )}
        closeClick={() => setInfoDialogOpen(false, "")}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"switch-basic-to-advanced-filter"}
        openDialog={openFilterDialog}
        bodyContent={filterModalBody}
        dialogActions={saveDialogActions}
        size={DIALOG_SIZE.MEDIUM}
        closeClick={closeFilterModal}
      />
      <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden"></div>
      <div ref={manageColsDrillOverlay} id="overlay-manage-columns-drill" className="uk-hidden"></div>
      <div
        id={"main-component-container"}
        className={"main-component-container " + (showSkeleton ? "hidden" : "")}
        style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
      >
        <Loader newLoader />
        <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
          <NewBanner
            bannerClassName={"header-banner scenario-banner"}
            labelClassName={"header-banner-label scenario-banner-label"}
            icon={<RoundInfoIcon className="info-banner-icon" />}
            label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
            body={
              <Button
                id={"banner-dismiss-btn"}
                label={lang.modal.buttons.dismiss}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => setScenarioBannerVisible(false)}
              />
            }
          />
        </div>

        <div className="main-report-header">
          <ReportHeader headerElements={headerElements} />
        </div>

        <iframe name="frame" id="frame_export" style={{ display: "none" }}></iframe>

        <div className="main_report">
          {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{lang.overlay_go_msg}</div>}
          <div id="main_report_container" className="main_report_container">
            {areStatesNotEmpty && headerElements.length > 0 && (
              <Heatmap
                checkForLimitAccessMessage={checkForLimitAccessMessage}
                clientPeriodsState={clientPeriodsState}
                columnProfiles={columnProfiles}
                customGroups={customGroups}
                dataLength={dataLength}
                datasetState={datasetState}
                dispatch={dispatch}
                exportQueryFilter={getExportQueryFilter}
                filterDialRef={filterDialRef.current}
                filterFinal={filterFinal}
                history={props.history}
                isDrilling={isDrilling}
                manageColsAccess={manageColsAccess}
                manageColsOverlay={manageColsOverlay}
                manageColsDrillOverlay={manageColsDrillOverlay}
                manageColumnsProps={manageColumnsProps}
                match={props.match}
                periodsStatusState={periodsStatusState}
                profileColumns={profileColumns}
                profiles={profiles}
                profitFormat={profitFormat}
                psLinesOptions={psLinesOptions && psLinesOptions.has(scenarioState.scenario) ? psLinesOptions.get(scenarioState.scenario)[0] : []}
                PSViewsState={PSViewsState}
                ref={reportRef}
                scenarioState={scenarioState}
                setDataLength={setDataLength}
                setIsDrilling={setIsDrilling}
                setIsListFullScreen={setIsListFullScreen}
                setProfitStackLines={setProfitStackLines}
                setShowGreyOverLay={setShowGreyOverLay}
                showGreyOverLay={showGreyOverLay}
                stateProfiles={stateProfiles}
                tablesToExcelWrapper={tablesToExcelWrapper}
                trackingData={trackingData}
                userAllowedMenuLinks={userAllowedMenuLinks}
                userSettingsState={userSettings}
                vectorState={vectorState}
                useNewReorderList={userSettings.useNewReorderList}
              />
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export { HeatmapWrapper };
