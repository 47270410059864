import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";


// import NewHeader from './NewHeader';
import AppliedFilters from "../../components/AppliedFilters";
import FilterByDropdown from "../../components/FilterByDropdown";
import NewBanner from "../../components/NewBanner";
import { DimensionsDropdowns, Loader } from "../../form/elements";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import SwitchToggle from "../../newComponents/SwitchToggle";
import FilterDialog from "../../sections/filter/FilterDialog";
import { ReportHeader } from "../ReportHeader";

import {
  ALL_REPORTS,
  ALL_WIDGETS,
  API_URL,
  BUTTON_DROPDOWN_VARIANT,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CURRENT_FILTER_COOKIE,
  DIALOG_SIZE,
  FILTER,
  FY_VALUES,
  HEADER_ELEMENT,
  MENU_ITEM,
  SAVED_FILTER_ID_COOKIE,
  SCENARIO_STATUS, SELECTED_DRILL_LIST,
  SIZES,
  UI_ACTIONS,
} from "../../class/constants";
import { lang } from "../../language/messages_en";

import {
    formatAdvancedFilter,
    formatBasicFilter,
    getLocalStorageValueByParameter,
    logout,
    setLocalStorageValueByParameter,
    updateWindowLoading,
} from "../../class/common";
import { generateQuarter, getPeriodDifference, getPeriodFromDate } from "../../class/date";
import {alertAndLogError, saveCookie, setDrillRowsLimit} from "../../class/jqueries";
import {
  areObjectsNotEmpty,
  checkIfMonthsValid,
  copyObjectValues,
  findOptionByKeyValue,
  getSectionExists,
  tryParse,
} from "../../class/utils";
import LandscapeBubble from "../../LandscapeBubble";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import { getNewEntityFilter } from "../../sections/filter/FilterHelperFunctions";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import {
  updateDatasetState,
  updatePeriodsStatusState,
  updatePsViewsState,
  updateVectorState,
} from "../../actions/scenariosActions";
import { getClientPeriods, getCustomViewData, getDatasets, getFilterEntities, getPeriodsStatus, getVectors } from "../api/api";
import { useAuthenticated, useCalendarToggle } from "../CustomHooks";
import {
  messageDialogContent,
  renderButton,
  renderPeriodCalendar,
  renderTitle,
  runExtendedProfitStack,
  tablesToExcel,
} from "../functions/componentFunctions";
import { addCorrespondingParenthesisToFilter } from "../functions/filterFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { disableVectors } from "../functions/vectorFunctions";
import $ from "jquery";

const _modified = " Modified";
const _selectedListProfile = "selectedProfile_list";
/**
 * @author Ralph Bejjani
 * @description component renders title based , filter,period calendar, vectors dropdown , Apply button, configure dropdown with  {@link LandscapeBubble} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props
 * @returns {@link ReportHeader}, {@link LandscapeBubble}
 */
const ProfitLandscapeWrapper = (props) => {
  /**Store */
  const profiles = useSelector((state) => state.profiles);
  const stateProfiles = useSelector((state) => state.stateProfiles);
  const customGroups = useSelector((state) => state.customGroups);
  const profileColumns = useSelector((state) => state.profileColumns);
  const columnProfiles = useSelector((state) => state.columnProfiles);
  const psLinesOptions = useSelector((state) => state.psLinesOptions);
  const quadrantTierOptions = useSelector((state) => state.quadrantTierOptions);
  const landscapeBubbleListData = useSelector((state) => state.landscapeBubbleListData);
  const landscapeBubbleObjBeforeBack = useSelector((state) => state.landscapeBubbleObjBeforeBack);
  const landscapeCostHierarchy = useSelector((state) => state.landscapeCostHierarchy);

  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;
  const switchClient = props.switchClient;

  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const manageColsOverlay = useRef();
  const dimensionDropdownsRef = useRef();
  const reportRef = useRef();
  const manageColsRef = useRef();
  const appliedFiltersRef = useRef();
  const filterDialRef = useRef();
  const filterByRef = useRef();
  const profitStackSelectedEntities = useRef();
  const dropDownRef = useRef();
  const exportRef = useRef();
  const exportRefVal = useRef();
  const confirmPassRef = useRef();
  const countRef = useRef(0);
  const configDropdownRef = useRef();
  const manageColumnsDrillRef = useRef();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar } = useCalendarToggle();

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [vectorState, setVectorState] = useState({});
  const [datasetState, setDatasetState] = useState({});
  const [PSViewsState, setPSViewsState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [calendarVisible, showCalendar] = useState(false);

  /**Filter Related States */
  const [filterFinal, setFilterFinal] = useState(
    props.history.location.state
      ? props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || []
        : []
      : []
  );
  const [filterFinalBasic, setFilterFinalBasic] = useState(
    props.history.location.state
      ? props.history.location.state.mainFilterFinalBasic
        ? tryParse(props.history.location.state.mainFilterFinalBasic) || []
        : []
      : []
  );
  const [basicFilterEntities, setBasicFilterEntities] = useState([]);
  const [exportQueryFilter, setExportQueryFilter] = useState("");
  const [displayFilter, setDisplayFilter] = useState();
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
  const [tempFilter, setTempFilter] = useState();
  const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
  const [count, setCount] = useState(0);
  const [basicFilterItem, setBasicFilterItem] = useState();
  const [drillFilter, setDrillFilter] = useState();

  /**Header Related States */
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0);
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(MENU_ITEM.FIELDS.PROFIT_LANDSCAPE);
  const [reportTitle, setReportTitle] = useState(ALL_REPORTS.PROFIT_LANDSCAPE);
  const [manageColumnsProfile, setManageColumnsProfile] = useState();
  const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
  const [limitAccessMsg, setLimitAccessMsg] = useState("");
  const [viewStackOpen, setViewStackOpen] = useState(false);
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [callGo, setCallGo] = useState(false);
  const [exportOptions, setExportOptions] = useState([]);
  const [exportValue, setExportValue] = useState();
  const [headerChanged, setHeaderChanged] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(lang.header.custom_text.download_start);
  const [dataLength, setDataLength] = useState(0);
  const [isDrilling, setIsDrilling] = useState(false);
  const [openInfoDialog, setOpenInfoDilog] = useState();
  const [infoDialogMsg, setInfoDialogMsg] = useState();
  const [openFilterDialog, setOpenFilterDialog] = useState();
  const [compareMode, setCompareMode] = useState(false);
  const [fullCompareSelection, setfullCompareSelection] = useState(false);
  const [hideCompare, setHideCompare] = useState(false);
  const [vectorSet, setVector] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState(0);

  const manageColumnsProps = {
    manageColumnsUpdateSections: [
      ALL_WIDGETS.FIELDS.LIST,
      MENU_ITEM.FIELDS.HEATMAP,
      MENU_ITEM.FIELDS.EROSION_AND_GROWTH,
      ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS,
    ],
  };
  const manageColsAccess = {
    [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS),
      [userAllowedMenuLinks]
    ),
    [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS),
      [userAllowedMenuLinks]
    ),
    [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS),
      [userAllowedMenuLinks]
    ),
    [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(
      () => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS),
      [userAllowedMenuLinks]
    ),
  };

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    reportRef?.current?.setCompareMode(true);
    if (scenarioState) {
      //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
      setPeriodsStatusState({});
      setVectorState({});
      setDatasetState({});
      setClientPeriodsState({});
      setShowGreyOverLay(true);
      getPeriodsStatus(
        scenarioState,
        dispatch,
        updatePeriodsStatusState,
        setPeriodsStatusState,
        props,
        profitFormat,
        userSettings,
        12
      );
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
      getVectors(
        scenarioState,
        dispatch,
        updateVectorState,
        setVectorState,
        reportTitle,
        profitFormat,
        props,
        false,
        filterFinal,
        userSettings,
        vectorState,
        filterDialRef
      );
      getDatasets(
        scenarioState,
        dispatch,
        updateDatasetState,
        setDatasetState,
        props,
        profitFormat,
        params,
        false,
        userSettings,
        datasetState
      );
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
      setExportOptionsForUser();
    }
  }, [scenarioState]);

  useEffect(() => {
    if (
      areObjectsNotEmpty([periodsStatusState, scenarioState, clientPeriodsState, vectorState, PSViewsState]) &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
      if (exportValue !== undefined) {
        setHeaderChanged(true);
      }
    }
  }, [
    periodsStatusState,
    clientPeriodsState,
    calendarVisible,
    vectorState,
    PSViewsState,
    manageColumnsProfile,
    showGreyOverLay,
    exportOptions,
    isDrilling,
    count,
    basicFilterEntities,
    filterFinalBasic,
    headerChangedCounter,
    manageColumnsProfile,
    exportValue,
    compareMode,
    fullCompareSelection,
    hideCompare,
    reportRef?.current?.state.compareMode
  ]);

  useEffect(() => {
    if (vectorState) {
      getCustomViewData(
        PSViewsState,
        vectorState,
        params,
        filterFinal,
        userSettings,
        scenarioState,
        dispatch,
        updatePsViewsState,
        setPSViewsState,
        false,
        props
      );
      // if (!showGreyOverLay) {
      //     apply(); // if no greyoverlay then call apply()
      // }
    }
  }, [vectorState]);

  useEffect(() => {
    if (callGo && !showGreyOverLay) {
      apply();
      setCallGo(false);
    }
  }, [callGo, manageColumnsProfile, filterFinal, filterFinalBasic]);

  const removeDisableVectors = (vectorOptions) => {

    const vectorsArray = vectorOptions.map(obj => {

      const newVectorObj = { ...obj };
      if (!obj.isGroupTitle) {
        delete newVectorObj.isDisabled;
      }

      return newVectorObj;
    });

    return vectorsArray

  }

  /**HeaderElements Related Functions */
  const getHeaderElements = () => {
    let sectionsData = getFilterBySectionsData();
    let isDropDownDisabled = isDrilling;
    let headerElements = [];
    let vectorOptions = disableVectors(vectorState.vectorOptions, true);
    let vectorsNotDisabled = removeDisableVectors(vectorState.vectorOptions)
    let nextVectors = copyObjectValues(vectorState.nextVectors);
    let tooltip =  isDrilling ? lang.exit_drill_to_proceed : "";

    if (
      vectorOptions?.filter((e) => e.value === nextVectors[0])[0] &&
      vectorOptions?.filter((e) => e.value === nextVectors[0])[0].isDisabled
    ) {
      let indexDim1 = vectorOptions.findIndex((e) => !e.isDisabled);
      var tempState = copyObjectValues(vectorState);
      if (indexDim1 > -1) {
        nextVectors[0] = vectorOptions[indexDim1].value;        
        tempState.nextVectors = nextVectors;
        tempState.vectorObjects = nextVectors.map((v) => {
          return findOptionByKeyValue(tempState.vectorOptions, "value", v);
        });
        if (nextVectors[0] !== tempState.vectors[0] && !vectorSet) {
          tempState.vectors = nextVectors;
          setVectorState(tempState);
          setVector(true);
        }
      }
    }

    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(reportTitle)}
        {renderPeriodCalendar(
          periodsStatusState,
          handleElementChange,
          undefined,
          clientPeriodsState.allPeriods,
          toggleCalendar,
          isDropDownDisabled,
          scenarioState,
          true,
          tooltip
        )}

        {/* <div key={Math.random()} className="template-report-title">by</div> */}
        <div
          key={Math.random()}
          uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}
          className={"uk-flex uk-flex-column uk-flex-start"}
        >
          <div key={Math.random()} uk-tooltip={isDrilling ? lang.exit_drill_to_proceed : "title:"} className={isDropDownDisabled ? " disabled" : ""}>
            <DimensionsDropdowns
              ref={dimensionDropdownsRef}
              disabled={reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT}
              numberOfDimensions={1}
              dimensionOptions={vectorOptions}
              vectorDisabled={vectorState.vectorDisabled || reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT}
              excludeOptions={true}
              onChangeDimension={(e, index, fromMount) => handleElementChange(HEADER_ELEMENT.VECTOR, e, fromMount, index, true)}
              shouldFetchData={false}
              selectedDimensions={vectorState.nextVectors}
              startAsEmpty={false}
              dropdownTooltip={undefined}
              hideLabel={true}
            />
          </div>
        </div>
        <div>
          {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), "", "", applyBtnClick, !showGreyOverLay || isDrilling)}
        </div>
      </div>
    );

    headerElements.push(
    <div className={"second_header_row gap_between_buttons "} style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}>
    <div uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}>
      <FilterByDropdown
          ref={filterByRef}
          sectionsData={copyObjectValues(sectionsData.data)}
          clearClick={clearClick}
          isClearBtnDisabled={sectionsData.isClearBtnDisabled}
          handleCheckboxChange={handleCheckBoxChange}
          basicFilterCountLimit={userSettings.basicFilterCountLimit}
          switchBasicToAdvanced={switchBasicToAdvanced}
          disabled={isDrilling}
      />
    </div>
      <div uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}>
        <FilterDialog
          id={"secondary_header_" + "landscape"}
          ref={filterDialRef}
          section_id={params.sectionId}
          vectorOptions={vectorsNotDisabled}
          profitFormat={"list"}
          mustHideFilter={false}
          categoryToolkit={false}
          onChangeFilter={onChangeFilter}
          quadTierIsAllowed={true}
          isTempScenario={false}
          scenario_id={scenarioState.scenario}
          mustFetchDatasets={false}
          user={userSettings.user}
          isDrilling={false}
          fromOptions={datasetState.datasetOptions}
          datasetOptions_id={datasetState.datasetOptions_id}
          dataSet={datasetState.dataset}
          useCookies={true}
          parentMessage={""}
          psFilterDisabled={false}
          quadrantsDisabled={false}
          quadrantDefault={false}
          checkIfMonthsValid={checkIfMonthsValid}
          comparisonScenarioId={undefined}
          filterDisplayEditable={true}
          psLinesOptions={psLinesOptions}
          quadrantTierOptions={quadrantTierOptions}
          dispatch={dispatch}
          savedFilterDataState={filterDialRef?.current?.state?.savedFilterData}
          isRedirectionFromStacks={false}
          savedFilter={true}
          fromSecondaryHeader={true}
          isDrilling={isDrilling}
          // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
        />
      </div>
      </div>
    );

    headerElements.push(
      <div className="third_row_header" style={{ display: "flex", width: "100%" }}>
        <div
          key={Math.random()}
          className={
            "noprint " +
            (displayFilter &&
            ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]"))
              ? "border-third-header "
              : "") +
            "uk-display-block"
          }
        >
          <AppliedFilters
            filterProps={filterFinalBasic}
            filterFinal={filterFinal}
            ref={appliedFiltersRef}
            userAllowedVectors={userSettings.user?.user_allowed_vectors}
            basicFilterCountLimit={userSettings.basicFilterCountLimit}
            handleCheckboxChange={handleBasicFilterCheckBoxChange}
            sectionsData={basicFilterEntities}
            fetchData={filterOnBasicFilter}
            clearFilter={filterDialRef?.current?.clearFilter}
            vectors={vectorState.vectorOptions}
            quadrantsDisabled={false}
            psFilterDisabled={false}
            parentMessage={"" /*props.filterWarningMessage*/}
            saveFilter={filterDialRef?.current?.startSaveFilter}
            clearBasicFilter={clearClick}
            clearFilterFromDialog={filterDialRef?.current?.clearFilter}
            forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
            className={isFilterDisabled ? "uk-disabled disabled" : ""}
            tooltip={"title:" + "" + ";pos: top-left"}
            filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
            isDrilling={isDrilling}
          />
        </div>
      </div>
    );
    headerElements.push(
      <>
      { !hideCompare && !isDrilling ?
      <div className="fourth_row_header" style={{ display: "flex", width: "100%", padding: "0.41667vw 0 0 0" }}>
        <div
          key={Math.random()}
          className={
            "noprint " +
            (displayFilter &&
            ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]"))
              ? "border-third-header "
              : "") +
            "uk-display-block"
          }
        >

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              <Button
                id="landscape-compare-btn"
                variant={
                  reportRef?.current?.state.fullCompareSelection || fullCompareSelection
                    ? BUTTON_VARIANT.PRIMARY
                    : BUTTON_VARIANT.SECONDARY
                }
                type={BUTTON_TYPE.DEFAULT}
                size={SIZES.DEFAULT}
                disabled={
                  (reportRef?.current?.state.compareMode && !fullCompareSelection) ||
                  showGreyOverLay ||
                  reportRef?.current?.state.notValidScenario
                }
                label="Compare Segments"
                leftIcon={<i className="heatmap-configure-btn fas fa-retweet"></i>}
                onBtnClick={() => {
                  setCompareMode(true);
                  reportRef?.current?.setCompareMode(undefined);
                  resetSelectedEntitiesOnExitDrill();
                }}
              />
              }
            {reportRef?.current?.state.compareMode ? (
              <div className="">
                <span className="select-segments-text compare-text-italic">
                  {fullCompareSelection ? "" : lang.profit_landscape.select_segments}
                  <span
                    className="stop-compare-text"
                    onClick={() => {
                      setCompareMode(false);
                      reportRef?.current?.setCompareMode(true);
                      resetSelectedEntitiesOnExitDrill()
                    }}
                  >
                    Stop Comparison
                  </span>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
            
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: "auto"}}>
          <ButtonDropdown
            id="landscape-configure-btn"
            ref={configDropdownRef}
            placeholder={lang.heatmap_configure.configure}
            className="heatmap-configure-btn"
            // onBtnClick={_this.showLandscapeConfigure}
            dropId={"configure-dropdown"}
            variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
            size={SIZES.SMALL}
            renderContainerBody={reportRef?.current?.renderConfigureComponent}
            firstAttr={"#button-drop-landscape-configure-btn"}
            secondAttr={"#configure-landscape-bubble-container"}
          />
        </div>
      </div>
      : ""}
      </>
    );

    return headerElements;
  };


  const handleElementChange = (name, e, fromMount = false, dimIndex = 0) => {
    switch (name) {
      case HEADER_ELEMENT.SELECTION_RANGE: {
        let selectedYears = e.filter((year) => year.isSelected);
        var tempState = copyObjectValues(periodsStatusState);
        if (selectedYears.length > 0) {
          let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : [];
          let sortedSelectedYears = selectedYears.map((p) => p.year + p.value).sort();
          let startPeriod = sortedSelectedYears[0];
          let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
          let startDate = new Date(periods.filter((e) => e.value === startPeriod)[0].start_date);
          let endDate = new Date(periods.filter((e) => e.value === endPeriod)[0].end_date);

          tempState.nextCustomStartDate = startDate;
          tempState.nextCustomEndDate = endDate;
          tempState.customStartDate = startDate;
          tempState.customEndDate = endDate;
          if (
            reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS &&
            !reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) &&
            !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS)
          ) {
            saveCookie("nextCustomStartDate", startDate);
            saveCookie("nextCustomEndDate", endDate);
          }
          setPeriodsStatusState(tempState);
          setShowGreyOverLay(true);
        }
        break;
      }
      case HEADER_ELEMENT.VECTOR: {
        var vectorValue = typeof e === "object" ? e.value : e;
        var tempState = copyObjectValues(vectorState);
        saveCookie(HEADER_ELEMENT.VECTOR, vectorValue);
        if (
          periodsStatusState.nextCustomStartDate &&
          periodsStatusState.nextCustomEndDate &&
          reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS &&
          reportTitle &&
          !reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) &&
          !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS)
        ) {
          saveCookie("nextCustomStartDate", periodsStatusState.nextCustomStartDate);
          saveCookie("nextCustomEndDate", periodsStatusState.nextCustomEndDate);
        }

        tempState.nextVectors = copyObjectValues(vectorState.nextVectors);
        tempState.nextVectors[dimIndex] = vectorValue;
        tempState.vectors = copyObjectValues(vectorState.nextVectors);
        tempState.vectors[dimIndex] = vectorValue;
        tempState.vectorObjects = tempState.vectors.map((v) => {
          return findOptionByKeyValue(tempState.vectorOptions, "value", v);
        });
        setVectorState(tempState);
        setShowGreyOverLay(true);
        break;
      }
      default:
        break;
    }
    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState + 1);
    reportRef?.current?.setCompareMode(true, true);
    setCompareMode(false);
  };

  const setInfoDialogOpen = (isOpen, infoMsg) => {
    setOpenInfoDilog(isOpen);
    setInfoDialogMsg(infoMsg);
  };

  const runApplyValidations = () => {
    if (
      getPeriodDifference(
        getPeriodFromDate(periodsStatusState.customStartDate),
        getPeriodFromDate(periodsStatusState.customEndDate)
      ) > 12
    ) {
      setInfoDialogOpen(true, lang.not_allowed_periods_selection);
      return false;
    }
    return true;
  };

  /**
   * This fucntion is the on click of the apply button in the header.
   * It triggers apply() action and remove full screen mode of the list.
   */
  const applyBtnClick = () => {
    apply();
    reportRef?.current?.expandingDefaultListRef?.current?.removeFullScreenMode();
    resetSelectedEntitiesOnExitDrill();
  }

  const apply = () => {
    if (!runApplyValidations()) {
      return;
    }
    let isDrillingValue = isDrilling;
    if (showGreyOverLay) {
      setIsDrilling(false);
      isDrillingValue = false;
    }
    reportRef?.current.go(isDrillingValue);
    setShowGreyOverLay(false);
  };

  /**Export Related functionalities */
  const setExportOptionsForUser = (hideExportOnList) => {
    let exportOptions = hideExportOnList ? [] : [{ value: "export_excel", label: "Export Excel" }];
    var hasExportCSVAccess = getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);
    if (hasExportCSVAccess) {
      exportOptions.push({ value: ALL_WIDGETS.EXPORT_ALL, label: lang.header.titles.csv });
      exportOptions.push({ value: ALL_WIDGETS.EXPORT_CSV_PSL, label: lang.header.titles.csv_all_psl });
    }
    setExportOptions(exportOptions);
  };

  const getExportValue = () => {
    return exportRefVal?.current;
  };

  const tablesToExcelWrapper = () => {
    // let zipCodeVector = _this.state.zipCodeVector;
    // let stateVector = _this.state.stateVector;
    // let stateVectorObj = _this.state.vectorOptions?.filter(e=>e.value.toLowerCase()+"name" === stateVector?.toLowerCase() || e.value.toLowerCase()+"number" === stateVector?.toLowerCase() || e.value.toLowerCase()+"number" === stateVector?.toLowerCase());
    // let zipCodeVectorObj = _this.state.vectorOptions?.filter(e=>e.value.toLowerCase()+"name" === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+"number" === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+"number" === zipCodeVector?.toLowerCase());
    // let dataTier= _this.state.zipCodeSelected && _this.isMainReport.isGeographyConcentration ? _this.state.vectorObjects[0].value :_this.state.boundary === BOUNDARIES.STATE && _this.isMainReport.isGeographyConcentration ? stateVectorObj && stateVectorObj.length > 0 ? stateVectorObj[0]?.label : "" : zipCodeVectorObj && zipCodeVectorObj.length > 0 &&  _this.isMainReport.isGeographyConcentration? zipCodeVectorObj[0]?.label : this.isMainReport.isProfitStackLineDefinitions ? reportName : findOptionByKey(this.state.vectorOptions, this.isMainReport.isSecondDimension ? this.state.listDimension : this.state.vector).label;
    tablesToExcel(reportTitle, userSettings, vectorState, datasetState, scenarioState, reportRef, getExportQueryFilter);
  };

  /**Drill Related Functions */
  const setIsDrillingFunc = (isDrilling) => {
    setIsDrilling(isDrilling);
  };

  const onBackNavigation = () => {
    reportRef.current.pageComponent.setState({
      submitDrill: undefined,
      drillColumns: undefined,
      sorter: "",
      cardsData: [],
      drillCardWidths: undefined,
      drillListWidths: undefined,
    }) // emptying sorter, drillColumn and submitDrill flag so they won't affect the request sent in profit list when we click on back
    setDrillFilter([])
    sessionStorage.removeItem(SELECTED_DRILL_LIST); //Remove when changing profile
    reportRef.current.pageComponent.tabulatorList.current.tabulator.clearFilter(true); //clears columns filter
    history.location.state.submitDrill = undefined;
  }

  const changeExpandingCSSOnExitDrill = () => {
    $("#bubble-chart-div").show();
    $(".expand_compress_button").show();
    $("#To_Scroll_Top").hide();
    $("#To_Scroll_List").show();
    $("#expanding-component").addClass("half-height");
    $("#expanding-component").removeClass("full-height");
    $("#bubble-chart-div").addClass("half-screen-main-component");
    reportRef.current.setState({
      isListFullScreen: false
    })
  }

  const resetTableOnExitDrill = () => {
    reportRef.current.pageComponent?.tabulatorList?.current?.tabulator.redraw()
    reportRef.current.pageComponent.setState({
      cardsData: [],
    });
  }

  const triggerExpandClickOnExitDrill = () => {
    let expandingRef = reportRef.current.expandingSelectedListRef.current || reportRef.current.expandingDefaultListRef.current;
    expandingRef.expandClick(true, false);
  }

  const resetSelectedEntitiesOnExitDrill = () => {
    reportRef.current.saveChosenEntities(true)
    profitStackSelectedEntities.current = [];
    setSelectedEntities(0);
  }

  const exitDrill = () => {
    setIsDrilling(false);
    $("#Old_Tier").text(vectorState.vectors[0]);
    triggerExpandClickOnExitDrill();
    changeExpandingCSSOnExitDrill();
    resetSelectedEntitiesOnExitDrill();
    onBackNavigation();
    resetTableOnExitDrill();
  };

  const saveDrillFilter = (filter) => {
    setDrillFilter(filter);
  };

  /**Filter Related Functions */
  const getExportQueryFilter = (additionalFilter) => {
    if (additionalFilter?.filter && typeof additionalFilter?.filter != "function") {
      additionalFilter = additionalFilter.filter;
    }
    return additionalFilter
      ? additionalFilter.find((f) => !f.isBasicFilter)
        ? formatAdvancedFilter(
            additionalFilter,
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          )
        : formatBasicFilter(additionalFilter, userSettings?.user?.user_allowed_vectors)
      : exportQueryFilter;
  };

  const switchBasicToAdvanced = () => {
    if (filterByRef?.current) {
      filterByRef.current.handleClose();
    }
    
    if(!filterFinal) {
      return;
    }
    
    let filterStr = filterFinal;
    let filter =
      typeof filterStr === "string"
        ? JSON.parse(filterStr)
        : filterStr.filter && typeof filterStr.filter !== "function"
        ? filterStr.filter
        : filterStr;

    filter = addCorrespondingParenthesisToFilter(filter);
    if (filter.find((e) => e.isBasicFilter)) {
      filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
    }
  };

  const onChangeFilter = (filterStr, afterDrilling, applyFilter = false, isClear, isSwitchBasic, filterDisplayName) => {
    let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : filterStr;
    // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
    if (
      !filterDisplayName &&
      (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null ||
        !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) ||
        getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined")
    ) {
      filter.map((item) => {
        item.filter_display_name = undefined;
      });
    }

    if (isSwitchBasic) {
      filter.map(function (item) {
        item.isBasicFilter = false;
      });
    }
    let tempState = {};

    tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if (isClear) {
      filter = "[]";
      tempState.filterFinalBasic = [];
    } else if (!filter.find((f) => !f.isBasicFilter)) {
      tempState.filterFinalBasic = copyObjectValues(filter).filter((e) => e.field === "number"); //from selecting a saved basic filter
      let filterObjs = copyObjectValues(filter);
      const unique = [...new Set(filterObjs.map((item) => item.vector))]; // [ 'A', 'B']
      for (var e in unique) {
        getFilterEntities(
          { value: unique[e] },
          vectorState,
          setBasicFilterEntities,
          basicFilterEntitiesState,
          profitFormat,
          userSettings,
          scenarioState,
          tempState.filterFinal,
          getCountRef,
          setCount,
          setCountRef
        );
      }
    } else if (applyFilter) {
      // from apply in advanced
      tempState.filterFinalBasic = [];
    }
    tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
    let currState = history.state;
    if (!applyFilter) {
      let tempState2 = { tempFilter: filterStr }; //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
      if (!isSwitchBasic) {
        tempState2.filterFinalBasic = tempState.filterFinalBasic;
        setFilterFinalBasic(tempState2.filterFinalBasic);
      }
      setTempFilter(filterStr);
      return;
    }

    //check if filter contains quadrant or quadrant/tier filter
    tempState.isAdvancedFilterApplied = isClear ? false : true;
    tempState.exportQueryFilter =
      filterStr === "[]"
        ? undefined
        : formatAdvancedFilter(
            JSON.parse(filterStr),
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          );

    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);
    setExportQueryFilter(tempState.exportQueryFilter);
    // setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
    setTempFilter(tempState.tempFilter);

    if (isClear) {
      filterStr = "[]";
    }
    if(history && history.location && history.location.state ){
        history.push({
          state: {
            outsideFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
            mainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
            filter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
            filterFinal: typeof filter !== "string" ? JSON.stringify(filter) : filter,
            originalMainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
            scenarioState: history?.location?.state?.scenarioState
          }
        })
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
    
    }
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
    resetSelectedEntitiesOnExitDrill();
  };

  const setCountRef = (count) => {
    countRef.current = count;
  };

  const getCountRef = () => {
    return countRef.current;
  };

  const getFilterBySectionsData = () => {
    let isClearBtnDisabled = false;
    let filterFinalBasicState = filterFinalBasic || [];
    let vectors = vectorState.vectorOptions?.filter(
      (f) => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle
    ); // remove vectors with entityCount <= threshold and that are not group title;
    if (vectors && filterFinalBasicState) {
      filterFinalBasicState =
        typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
      filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
      isClearBtnDisabled = filterFinalBasicState.length === 0;
      vectors = vectors.map((item) => ({
        ...item,
        checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value), // .some() checks if any of the element.vector === item.value
      }));
    }

    // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
    let sectionsJson = [];
    let quarters = [];
    var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
    var endQuarter = datasetState.nextEndQuarter?.split("Q")[1];
    var startYear = datasetState.nextStartQuarter?.split("Q")[0];
    var endYear = datasetState.nextEndQuarter?.split("Q")[0];
    quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
    vectors = vectors; // add isDisabled to vectors that are not generated

    let suggestedVectors = vectors?.filter((f) => vectorState.nextVectors?.includes(f.value) && !f.isGroupTitle && !f.isDisabled); // get nextVectors as jsonObject
    suggestedVectors = suggestedVectors?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold); // checks if header vectors' entity count <= thereshold
    let hasSuggested = suggestedVectors?.length > 0;
    if (hasSuggested) {
      sectionsJson.push({
        sectionTitle: lang.suggested,
        itemsList: suggestedVectors,
      });
    }

    sectionsJson.push({
      sectionTitle: lang.all,
      itemsList: vectors,
    });
    return { data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled };
  };

  const renderHeader = () => {
    let countState = countRef.current;
    setCountRef(countState + 1);
    setCount(countState + 1);
  }

  const handleCheckBoxChange = (item) => {
    let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
    let isAdvancedFilterApplied =
      filterFinalArr &&
      filterFinalArr !== "[]" &&
      (typeof filterFinalArr === "object"
        ? filterFinalArr.find((e) => !e.isBasicFilter)
        : JSON.parse(filterFinalArr).find((e) => !e.isBasicFilter));
    if (isAdvancedFilterApplied) {
      setBasicFilterItem(item);
      openFilterModal();
    } else {
      handleBasicFilterCheckBoxChange(item);
    }
  };

  const filterOnBasicFilter = (basicAppliedFilter) => {
    let filters = copyObjectValues(basicAppliedFilter.filter((e) => e.entities.length));
    filters?.forEach((e) => {
      e.isBasicFilter = true;
      e.filter_display_name = undefined;
      e.entities = e.entities.map((ent) => {
        ent.value = ent.number;
        ent.label = ent.number;
        return ent;
      });
    });
    let filtersByName = copyObjectValues(filters);
    filtersByName?.forEach((e) => {
      e.field = "name";
      e.entities = e.entities.map((ent) => {
        ent.value = ent.name;
        ent.label = ent.name;
        return ent;
      });
      filters.push(e);
    });

    if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    let filterFinalBasic = copyObjectValues(basicAppliedFilter); //typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }
    filterFinalBasic?.forEach((e) => {
      e.filter_display_name = undefined;
    });

    filters = addCorrespondingParenthesisToFilter(filters);
    if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    setFilterFinal(JSON.stringify(filters));
    setFilterFinalBasic(filterFinalBasic);
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
      setCallGo(true);
      // apply();
    }
    resetSelectedEntitiesOnExitDrill();
  };

  // const renderConfigureBody = () => {
  //     return (
  //       <div className='configure-dropdown'>
  //         <div className='label-configure-landscape'>X Axis</div>
  //         <Container
  //           data={reportRef.current.selectedConfObjPSLines.xAxisPSLines}
  //           onChange={(e) => reportRef.current.handleAxisChange(e, true)}
  //           mode={"radioSelect"}
  //           className={
  //             "x-axis landscape-configure heatmap-configure dropdown-tree uk-margin-small-top"
  //           }
  //         />
  //         <div className='label-configure-landscape uk-margin-small-top'>Y Axis</div>
  //         <Container
  //           data={reportRef.current.selectedConfObjPSLines.yAxisPSLines}
  //           onChange={(e) => reportRef.current.handleAxisChange(e, false)}
  //           mode={"radioSelect"}
  //           className={
  //             "y-axis landscape-configure heatmap-configure dropdown-tree uk-margin-small-top"
  //           }
  //         />
  //         <div className='label-configure-landscape uk-margin-small-top'>Bubble Size</div>
  //         <DropDown
  //           id="select-set"
  //           className="landscape-configure heatmap-configure uk-cursor-pointer uk-margin-small-top input__dropdown"
  //           value={findOptionByKey(reportRef.current.state.bubbleSize, reportRef.current.state.bubbleSizeSelection)}
  //           onChange={(e) => reportRef.current.handleBubbleSizeChange(e)}
  //           options={reportRef.current.state.bubbleSize}
  //           class={"heatmap-configure configure-table-data-select"}
  //           type={DROPDOWN_TYPE.INPUT}
  //         />
  //       </div>
  //     )
  //   }

  const handleBasicFilterCheckBoxChange = (item, clear, entity, allEntities) => {
    let tempState = {};
    let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
    let initialFilter = copyObjectValues(filter);

    filter = filter || [];
    let removedLine = [];
    let isClear = false;
    let isUnCheck = false;
    if (entity && item) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          if (entity.checked === true) {
            filter[e].entities.push(entity);
          } else {
            filter[e].entities = filter[e].entities.filter((f) => f.value !== entity.value);
          }
        }
      }
    } else if (item && allEntities) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          for (let j in allEntities) {
            filter[e].entities.push(allEntities[j]);
          }
        }
      }
    } else if (item) {
      if (filter?.find((e) => e.vector === item.value)) {
        removedLine = filter.find((e) => e.vector === item.value);
        filter = filter.filter((e) => e.vector !== item.value);
        delete basicFilterEntities[item.value];
        isUnCheck = true;
      } else {
        let selectedEmptyFilter = getNewEntityFilter(
          item.value,
          FILTER.VALUES.FIELD.NUMBER,
          [],
          FILTER.VALUES.FILTER_ROW_TYPE.FILTER,
          lang.ui_filter.dropdowns.and
        );
        selectedEmptyFilter.vectorLabel = item.label;
        filter?.push(selectedEmptyFilter);
      }
    } else if (clear) {
      filter = [];
      tempState.filterFinal = [];
      isClear = true;
      if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(tempState.exportQueryFilter);
      }
    }
    filter = addCorrespondingParenthesisToFilter(filter);
    if (isUnCheck && removedLine.entities.length > 0) {
      tempState.filterFinalBasic = filter;
      let copyFilter = copyObjectValues(filter);
      copyFilter.forEach((e) => {
        if (e.entities) {
          e.isBasicFilter = true;
          e.entities = e.entities.map((ent) => {
            ent.value = ent.number;
            ent.label = ent.number;
            return ent;
          });
        }
      });
      let filtersByName = copyObjectValues(copyFilter);
      filtersByName.forEach((e) => {
        e.field = "name";
        e.entities = e.entities.map((ent) => {
          ent.value = ent.name;
          ent.label = ent.name;
          return ent;
        });
        copyFilter.push(e);
      });
      tempState.filterFinal = JSON.stringify(copyFilter);
      tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors);
    }
    filter.map((item) => {
      item.filter_display_name = undefined;
    });
    tempState.filterFinalBasic = isUnCheck ? JSON.stringify(filter) : filter;
    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);

    if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
      basicFilterEntities[item.value].map(entity => entity.checked = false); 
    } 
    
    let countState = countRef.current;
    setCount(countState + 1);
    countRef.current = countState + 1;
    // when we add the first dropdown, the initial filter will be 0 and the new filter will be 1.
    // when we remove the first dropdown, the filter will be 0 and the initial will be 1
    // this condition is to prevent resizing the tables when we add/remove more than 1 dropdown
    // if(isClear || (initialFilter.length <= 1 && filter.length === 0) || (initialFilter.length ===0 && filter.length <= 1)) {
    //     _this.handleOverlayAndHeights(filter.length > 0);
    // }
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }

    if (isUnCheck && removedLine.entities.length > 0 && !showGreyOverLay) {
      if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
      }
      let countstate = countRef.current;
      setCount(countstate + 1);
      countRef.current = countState + 1;
      // apply();
      setCallGo(true);
    } else if (!isClear) {
      getFilterEntities(
        item,
        vectorState,
        setBasicFilterEntities,
        basicFilterEntitiesState,
        profitFormat,
        userSettings,
        scenarioState,
        tempState.filterFinal,
        getCountRef,
        setCount,
        setCountRef
      );
    }
  };

  const clearClick = () => {
    handleBasicFilterCheckBoxChange(undefined, true);
    if (!showGreyOverLay) {
      setCallGo(true);
      // apply();
    }
  };

  const openFilterModal = () => {
    setOpenFilterDialog(true);
  };

  const closeFilterModal = () => {
    setOpenFilterDialog(false);
  };

  const filterModalBody = () => {
    return (
      <h4>
        {lang.clear_advanced_filter}
        <br />
        {lang.switching_to_quick_filter}
      </h4>
    );
  };

  const handleBasicFilterAndClose = () => {
    closeFilterModal();
    clearAdvancedFilter(basicFilterItem);
  };

  const clearAdvancedFilter = (basicFilterItem) => {
    filterDialRef?.current?.clearFilter();
    setFilterFinal([]);
    handleBasicFilterCheckBoxChange(basicFilterItem);
  };

  const saveDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.clear_filter}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={handleBasicFilterAndClose}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeFilterModal}
        />
      </>
    );
  };

  /**Profit Stack DropDown Functions */
  const toggleDropDownState = (status) => {
    dropDownRef.current.setDisabled(status);
    setViewStackOpen(false);

    if (status) {
      $(".profit-stack-filter-container").addClass("disabled-profit-stack-wrapper");
    } else {
      $(".profit-stack-filter-container").removeClass("disabled-profit-stack-wrapper");
    }
    $(".profit-stack-filter-container-div").attr("uk-tooltip", status ? lang.select_entities_to_view_PS : "title:");
  };

  /**Limit Access Related Functions */
  const checkForLimitAccessMessage = (data, showModal) => {
    let noAccessMessage = data.no_access_message;
    if (!!noAccessMessage && showModal && !props.checkedPsLines) {
      setLimitAccessDialogOpen(true, noAccessMessage);
    }
    return !!noAccessMessage;
  };

  const setLimitAccessDialogOpen = (isOpen, msg) => {
    setLimitAccessMsg(msg);
    setOpenLimitAccessDialog(isOpen);
  };

  /**Entity Stacks Redirection Related Functions */
  const saveChosenEntities = (entities) => {
    profitStackSelectedEntities.current = entities;
    setSelectedEntities(entities?.length);
  };

  const runExtendedProfitStackFunc = (dropdownName, PSViewsTempState) => {
    runExtendedProfitStack(
      dropdownName,
      vectorState,
      datasetState,
      profitFormat,
      profitStackSelectedEntities,
      reportRef,
      PSViewsTempState,
      periodsStatusState,
      scenarioState,
      filterDialRef,
      filterFinal,
      filterFinalBasic,
      manageColumnsProfile,
      userAllowedMenuLinks,
      history,
      isDrilling
    );
  };

  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";

  let showSkeleton = !(
    areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) && headerElements.length > 0
  );

  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton])

  return (
    <Suspense fallback={<LoaderSkeleton />}>
      {showSkeleton && <LoaderSkeleton />}
      <div id="overlay-selectedBubbleList" className="overlay-manage-columns uk-hidden" />
      <div id="overlay-defaultBubbleList" className="overlay-manage-columns uk-hidden" />
      <div id="overlay-drill-bubble-list" className="overlay-manage-columns uk-hidden" />
      <Modal
        id={"check-limit-access-dialog"}
        openDialog={openLimitAccessDialog}
        closeClick={() => setLimitAccessDialogOpen(false, "")}
        bodyContent={() => messageDialogContent(limitAccessMsg)}
        dialogActions={() => (
          <Button
            label={lang.modal.buttons.yes}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            aria-label="Close"
            onBtnClick={() => setLimitAccessDialogOpen(false, "")}
          />
        )}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"header-info-dialog"}
        openDialog={openInfoDialog}
        bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
        dialogActions={() => (
          <Button
            label={lang.modal.buttons.ok}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => setInfoDialogOpen(false, "")}
          />
        )}
        closeClick={() => setInfoDialogOpen(false, "")}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"switch-basic-to-advanced-filter"}
        openDialog={openFilterDialog}
        bodyContent={filterModalBody}
        dialogActions={saveDialogActions}
        size={DIALOG_SIZE.MEDIUM}
        closeClick={closeFilterModal}
      />
      <div id="toastExport" className={!!error ? "toast toast-fail" : "toast toast-success"}>
        <div id="desc">
          <i
            className={
              "fa-lg fas uk-margin-small-right " + (!!error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")
            }
            aria-hidden="true"
          ></i>
          {message}
          <span></span>
        </div>
      </div>
      <iframe name="frame" id="frame_export" style={{ display: "none" }}></iframe>
      <div
        id={"main-component-container"}
        className={"main-component-container " + (showSkeleton ? "hidden" : "")}
        style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
      >
        <Loader newLoader />
        <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
          <NewBanner
            bannerClassName={"header-banner scenario-banner"}
            labelClassName={"header-banner-label scenario-banner-label"}
            icon={<RoundInfoIcon className="info-banner-icon" />}
            label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
            body={
              <Button
                id={"banner-dismiss-btn"}
                label={lang.modal.buttons.dismiss}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => setScenarioBannerVisible(false)}
              />
            }
          />
        </div>

        <div className="main-report-header">
          <ReportHeader headerElements={headerElements} />
        </div>
        <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden"></div>
        <div className="main_report">
          {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{lang.overlay_go_msg}</div>}
          <div id="main_report_container" className="main_report_container">
            {areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) && headerElements.length > 0 ? (
              <LandscapeBubble
                checkForLimitAccessMessage={checkForLimitAccessMessage}
                clientPeriodsState={clientPeriodsState}
                columnProfiles={columnProfiles}
                configDropdownRef={configDropdownRef}
                customGroups={customGroups}
                dataLength={dataLength}
                datasetState={datasetState}
                dispatch={dispatch}
                exportOptions={exportOptions}
                filterDialRef={filterDialRef?.current}
                filterFinal={filterFinal}
                filterFinalBasic={filterFinalBasic}
                history={props.history}
                landscapeBubbleListData={landscapeBubbleListData}
                landscapeBubbleObjBeforeBack={landscapeBubbleObjBeforeBack}
                landscapeCostHierarchy={landscapeCostHierarchy}
                location={props.history.location}
                manageColsAccess={manageColsAccess}
                manageColumnsProps={manageColumnsProps}
                match={props.match}
                periodsStatusState={periodsStatusState}
                profileColumns={profileColumns}
                profiles={profiles}
                profitFormat={profitFormat}
                PSViewsState={PSViewsState}
                ref={reportRef}
                scenarioState={scenarioState}
                setDataLength={setDataLength}
                setfullCompareSelection={setfullCompareSelection}
                showGreyOverLay={showGreyOverLay}
                stateProfiles={stateProfiles}
                tablesToExcel={tablesToExcelWrapper}
                userAllowedMenuLinks={userAllowedMenuLinks}
                userSettingsState={userSettings}
                vectorState={vectorState}
                setShowGreyOverLay={setShowGreyOverLay}
                exportQueryFilter={getExportQueryFilter()}
                getExportQueryFilter={getExportQueryFilter}
                psLinesOptions={
                  psLinesOptions && psLinesOptions.has(scenarioState.scenario)
                    ? psLinesOptions.get(scenarioState.scenario)[0]
                    : []
                }
                setHideCompare={setHideCompare}
                renderHeader={renderHeader}
                setCompareMode={setCompareMode}
                saveChosenEntities={saveChosenEntities}
                profitStackSelectedEntities={profitStackSelectedEntities}
                setIsDrilling={setIsDrilling}
                isDrilling={isDrilling}
                saveDrillFilter={saveDrillFilter}
                drillFilter={drillFilter}
                manageColumnsDrillRef={manageColumnsDrillRef}
                exitDrill={exitDrill}
                setSelectedEntities={setSelectedEntities}
                resetSelectedEntitiesOnExitDrill={resetSelectedEntitiesOnExitDrill}
                useNewReorderList={userSettings.useNewReorderList}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export { ProfitLandscapeWrapper };
