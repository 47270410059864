//This should be the main component
import React, { Component } from 'react';
import { copyObjectValues, tryParse, findOptionByKey, capitaliseFirstLetterAfterChar, getSectionExists} from '../../class/utils.js';
import ProfitStackTabulator from '../../tables/deepDive/ProfitStackTabulator.js';
import BridgeChart from './BridgeChart';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import { getMonthName ,getActualYear,getPeriodTextFromMonth} from '../../class/date';
import { API_URL, YEAR_OVER_YEAR, PREVIOUS_ADJACENT, VECTOR_ANALYSIS, VECTOR_GROUPS, HEADER_ELEMENT, TOP_QUADRANT, TOP_QUADRANT_TIER, BP_QUADRANT_TIER, PSL_RETURN_NAMES, NONE, FILTER, BRIDGE_REPORT_GROUP_BY, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, DIALOG_SIZE,FY_VALUES, ROLLING_SEGMENTS, SEGMENTS } from '../../class/constants.js';
import shortid from 'shortid';
import BridgeDrillTable from '../../tables/BridgeDrillTable.js';
import { lang } from '../../language/messages_en.js';
import ExcelDetailsTable from '../../tables/ExcelDetailsTable.js';
import { vectorSpacing } from '../../class/jqueries.js';
import Legend from "../../components/Legend";
import Card from '../../Items/Card.js';
import { convertPxToViewport } from '../../class/formatting.js';
import { DialogContent, DialogActions } from '@mui/material';
import { findIndexOfValue } from '../../class/array.js';
import { CustomSelect } from "../../form/elements.js";
import { replaceSpecialChars } from '../../class/string.js';
import Button from '../../newComponents/Button.js';
import DropDown from '../../newComponents/DropDown.js';
import Modal from '../../newComponents/Modal.js';
import { Segment } from '../../components/Segment.js';
import { handleCurrency } from '../../class/common.js';
const $ = require('jquery');
const SUMMARY = "summary";
const PSL_LINES = "Profit Stack Lines";
const NEW_SALES = "New sales";
const NO_SALES = "No sales";
const COMPARISON = "Comparison ";
const SELECTION = "Selection ";
const PROFIT_STACK = "profit_stack";
class BridgeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: [],
            chartUnit: "$",
            showDrillTable:false,
            finishedLoading:false,
            isTableFullScreen:true,
            isDrillTableFullScreen:true,
            cardsData:[],
            groupedByVector: {value: NONE.value, label:NONE.label}
        };

        this.fetchAPI = fetchAPI.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.expandReport = this.expandReport.bind(this);
        this.fetchBridgeData = this.fetchBridgeData.bind(this);
        this.handleChangeBridgeFilter = this.handleChangeBridgeFilter.bind(this);
        this.handleChangeBridgeVector = this.handleChangeBridgeVector.bind(this);
        this.getExportOpts = this.getExportOpts.bind(this);
        this.getDownloadFormatterParams = this.getDownloadFormatterParams.bind(this);
        this.fetchBridgeTableData = this.fetchBridgeTableData.bind(this);
        this.bridgeCardRef = [];

         // Create a ref to the element we want to observe
        this.bridgeChartRef = React.createRef();
        // Initialize the ResizeObserver object
        this.resizeObserver = null;
        this.comparisonOptions = copyObjectValues(lang.header.options.comparison).map(function(item){item.description = item.description?.replaceAll(SEGMENTS.PEAK.label,new Segment().getSegmentObject(SEGMENTS.PEAK.label)?.label); return item});

    }

/**
 * fetches vectors of higher level to display in grouped by vectors dropdown
 */
    fetchHigherLevelVectors=()=>{
        let _this = this;
        let periods = _this.props.computePriorPeriods();
        let periodObject = {};
        let isBP = false;

        let headerRef = _this?.props?.headerRef

        let isYOY = this.props.comparisonValue === YEAR_OVER_YEAR;
        periodObject = _this.props.getPeriodsObject();
        let generatedId = this.props.mixVector+"_"+this.props.scenario+"_"+"'"+ periods[0] +"'"+"_"+"'"+ periods[1] +"'"+"_"+(!isBP ? periodObject.months : "")+"_"+(!isBP ?  periodObject.quarter : "")
            +"_"+ this.props.comparisonValue+"_"+(!isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"")+"_"+this.props.selectedItems+
            "_"+this.props.vector+"_"+(JSON.stringify({filter: this.props.additionalFilter? tryParse(this.props.filter).concat(this.props.additionalFilter): tryParse(this.props.filter)}))
            +"_"+(!isBP ?  periodObject.quarter : "")+"_"+(!isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"")+"_"+this.props.isProfitOpp;
        if (this.state.uniqueId === generatedId) {
            return;
        }
        var query = {
            action: "getHigherLevelVectors",
            mixVector: this.props.mixVector,
            scenario_id: this.props.scenario,
            currentPeriods: "'"+ periods[0] +"'",
            previousPeriods: "'"+ periods[1] +"'",
            fy: !isBP ? periodObject.months : "",
            quarter: !isBP ?  periodObject.quarter : "",
            comparison: this.props.comparisonValue,
            preQuarter: !isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"",
            vectors:JSON.stringify(_this.props.vectorOptions),
            selectedEntities: this.props.selectedItems,
            vector: this.props.vector,
            filter: JSON.stringify({filter: this.props.additionalFilter? tryParse(this.props.filter).concat(this.props.additionalFilter): tryParse(this.props.filter)}),
            quarter: !isBP ?  periodObject.quarter : "",
            preQuarter: !isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"",
            rollingPeriod: periodObject.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,

        }
        
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;
        var onThenCallback = (data)=>{
            let arr = data.result[0];
            let finalArr = [];
            let vectors = copyObjectValues(this.props.vectorOptions);
            let _order = "ORDER";
            let _orderBQ = "ORDER1"
            for (var e in vectors) {
                if (!vectors[e].isDisabled && vectors[e].value !== NONE.value) {
                    if (Number(arr[vectors[e].value]) <=1 || (vectors[e].value === _order && Number(arr[_orderBQ]) <=1 )) {
                        finalArr.push(vectors[e]);
                    }
                } else {
                    finalArr.push(vectors[e]);
                }
            }
            finalArr = finalArr.filter(e=>e.value !== _this.props.vector);
            let index = 0;
            if (finalArr.filter(e=>e.vector_group === VECTOR_GROUPS.CUSTOMER).length === 1) {
                index = finalArr.findIndex(x => x.isDisabled && x.vector_group === VECTOR_GROUPS.CUSTOMER);
                finalArr.splice(index,1);
            }
            if (finalArr.filter(e=>e.vector_group === VECTOR_GROUPS.PRODUCT).length === 1) {
                index = finalArr.findIndex(x => x.isDisabled && x.vector_group === VECTOR_GROUPS.PRODUCT);
                finalArr.splice(index,1);
            }
            if (finalArr.filter(e=>e.vector_group === VECTOR_GROUPS.OPERATIONS).length === 1) {
                index = finalArr.findIndex(x => x.isDisabled && x.vector_group === VECTOR_GROUPS.OPERATIONS);
                finalArr.splice(index,1);
            }
            _this.setState({
                vectorsGroupBy :finalArr,
                uniqueId: generatedId
            })
        }
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getHigherLevelVectors",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: this.props.showLoader,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_VECTORS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.bridge_report.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.bridge_report.requests_description.higher_level,
            [FETCHAPI_PARAMS.vector]: this.props.vector,
            [FETCHAPI_PARAMS.periods]: "Current Periods: '"+ periods[0] +"', Previous Periods: '"+ periods[1] +"'",
        }
        this.fetchAPI(fetchOptions);
    }

    fetchBridgeData(isMixVector, label, isBridgeReportDrill, drillVector, key, fromGo) {
        const _this = this;
        let groupBy = !fromGo && _this.state.groupedByVector?_this.state.groupedByVector.value:""
        if(groupBy === NONE.value){
            groupBy ="";
        }
        let periods = _this.props.computePriorPeriods();
        if (!groupBy &&!isBridgeReportDrill && isMixVector && this.props.drill_data && this.props.drill_data.columns.length > 0 && this.props.drill_data.order === "") {
            _this.pageComponent.showHideColumns(label, this.props.bridgeName);
            _this.pageComponent.setSortForLabel(label);
            return;
        }
        let periodObject = {};
        let isBP = false;
        let isYOY = this.props.comparisonValue === YEAR_OVER_YEAR;
        if (this.props.comparisonValue === TOP_QUADRANT || this.props.comparisonValue === TOP_QUADRANT_TIER  || this.props.comparisonValue === BP_QUADRANT_TIER) {
            isBP = true
        }
        periodObject = _this.props.getPeriodsObject();
        
        if(typeof _this.props.loaderCallback === 'function'){
            _this.props.loaderCallback(true);
        }

        let concatenatedFilter = this.props.filter?copyObjectValues({filter: tryParse(this.props.filter)}):"";
        let dashboardFilterObj = "";
        let dashBoardFilter = tryParse(this.props.dashboardFilter);
        if(this.props.dashboardFilter && dashBoardFilter.length>0){
            if(concatenatedFilter.filter.length>0){
                concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                concatenatedFilter.filter[concatenatedFilter.filter.length -1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            }
            dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
            dashBoardFilter[0].logical_operator = "AND";
            dashBoardFilter[dashBoardFilter.length-1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            dashBoardFilter.forEach(e=>{
                dashboardFilterObj = e;
                concatenatedFilter.filter.push(dashboardFilterObj);
            })
        }
        var query = {
            action: "getBridgeReport",
            bridgeCustomViewId: this.props.bridgeCustomViewId,
            reportId: this.props.bridgeReportId? this.props.bridgeReportId.toString() : "",
            vector: this.props.vector,
            filter: JSON.stringify({filter: this.props.additionalFilter?.length > 0 ? tryParse(this.props.filter, []).concat(this.props.additionalFilter): (this.props.fromWidget && dashboardFilterObj? concatenatedFilter.filter : tryParse(this.props.filter, [])) ?? []}),
            bridge: this.props.bridge,
            mixVector: this.props.mixVector,
            mixVectorLabel: this.props.vectorOptions?.find(f => f.value === this.props.mixVector)?.label,
            scenario_id: this.props.scenario,
            selectedEntities: this.props.selectedItems,
            currentPeriods: "'"+ periods[0] +"'",
            previousPeriods: "'"+ periods[1] +"'",
            comparison: this.props.comparisonValue,
            selectedNames: this.props.selectedNames,
            isPV: this.props.isPV,
            peerGroup:this.props.peerGroup,
            peerKeys: this.props.peerKeys,
            barLabel: isMixVector ? label :"",
            fy: !isBP ? periodObject.months : "",
            quarter: !isBP ?  periodObject.quarter : "",
            preQuarter: !isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"",
            limit: this.props.limit,
            isBridgeReportDrill:isBridgeReportDrill,
            drillVector: drillVector,
            vectorKey: key,
            groupByVector: isBridgeReportDrill ? "" : groupBy,
            rollingPeriod: periodObject.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
        }
        
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;
        var onThenCallback = (data)=>{
            if(_this.props.headerRef){
                _this.props.headerRef.disableExportExcel(false);
                _this.bridgeTableRef?.exportBtnRef?.current?.setIsDisabled(false)

            }

            if (data.err || data.no_access_message) {
                _this.setState({
                    isError: true,
                    bridgeTableData: [],
                    chartData: [],
                    dataId: shortid.generate(),
                }, function () {
                    if(_this.props.dontRenderTable){
                        _this.props.handleAPIError(lang.dashboards.messages.no_permission);
                    }else{
                        _this.setOpenWarning();
                    }
                })
                return;
            }
            if (data && !isMixVector) {
                let tempState = {}
                _this.sentRequests -= 1;
                let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
                if(hasInvalidAccess) {
                    tempState.chartData = [];
                } else {
                    tempState.chartData = data.data;
                }
                if(_this.props.dontRenderTable){
                    tempState.finishedLoading= true;
                }
                _this.setState(tempState);
                if(_this.props.setDrillData){
                    _this.props.setDrillData([], [], "")
                }

            }
            if (isMixVector) {
                _this.props.setDrillData(data.data, data.columns, label, isBridgeReportDrill)
            }
            if(typeof _this.props.loaderCallback === 'function'){
                _this.props.loaderCallback(false);
            }
            // if (data && isMixVector) {
            //     tempState.drill_data = data.data;
            //     _this.setState(tempState);
            // }
            if(_this.pageComponent){
                _this.pageComponent.expandedColumns =[];
            }
        };

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getBridgeReport",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: this.props.showLoader || this.props.isDrilling,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_VECTORS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: this.props.isDashboards? lang.observability.output.dashboards.screen_name : lang.observability.output.bridge_report.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: isBridgeReportDrill? lang.observability.output.bridge_report.requests_description.drill_from_drill : isMixVector? lang.observability.output.bridge_report.requests_description.drill : (lang.observability.output.bridge_report.requests_description.chart + (this.props.isDashBoards? lang.observability.output.dashboards.widget :"" )),
            [FETCHAPI_PARAMS.vector]: isBridgeReportDrill? drillVector : this.props.vector,
            [FETCHAPI_PARAMS.periods]: "Current Periods: '"+ periods[0] +"', Previous Periods: '"+ periods[1] +"'",
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        this.fetchAPI(fetchOptions);
    }

    fetchBridgeTableData() {
        var periods =this.props.computePriorPeriods();
        const _this = this;
        let periodObject = {};
        let isBP = false;
        let isYOY = this.props.comparisonValue === YEAR_OVER_YEAR;
        if (this.props.comparisonValue === TOP_QUADRANT || this.props.comparisonValue === TOP_QUADRANT_TIER  || this.props.comparisonValue === BP_QUADRANT_TIER) {
            isBP = true
        }
        periodObject =_this.props.getPeriodsObject();
        
        var query = {
            action: "fetchBridgeTableData",
            scenario_id: this.props.scenario,
            comparison: this.props.comparisonValue,
            vector: this.props.vector,
            currentPeriods: periods[0],
            previousPeriods: periods[1],
            filter: JSON.stringify({'filter': this.props.additionalFilter?.length > 0 ? tryParse(this.props.filter, []).concat(this.props.additionalFilter) : tryParse(this.props.filter, []) ?? []}),
            entities: this.props.selectedItems,
            isPV: this.props.isPV,
            peerGroup:this.props.peerGroup,
            peerKeys: this.props.peerKeys,
            view_id: this.props.bridgeCustomViewId,
            fy: !isBP ? periodObject.months : "",
            quarter: !isBP ?  periodObject.quarter : "",
            preQuarter: !isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"",
            rollingPeriod: periodObject.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12
        }
        
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;

        this.setState({
            finishedLoading: false
        })


        var onThenCallback = (data)=>{
            if (data) {
                _this.sentRequests -= 1;
                let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
                if(hasInvalidAccess || data.error) {
                    _this.setState({
                        bridgeTableData: [],
                        chartData: [],
                        dataId: shortid.generate()
                    },function(){
                        if(data.error){
                            _this.setOpenWarning();
                        }
                    })
                    return;
                }
                if(_this.state.openWarning === true){
                    data.data = [];
                }
                var columns = data.columns;
                if (columns && columns.length > 0) {
                    columns.forEach(col => {
                      col.title = handleCurrency(col.title);
                        if (col.field !== VECTOR_ANALYSIS.FIELDS.LEVEL ) {
                            col.columns.forEach(c => {
                                c.dontFilter = true;
                                c.headerSort = false
                                if (c.field && ((c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.COMPARISON.toUpperCase()) || c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.SELECTION.toUpperCase())) && !c.field.includes(SUMMARY))) {
                                    c.visible = false;
                                }
                                if (c.field === VECTOR_ANALYSIS.FIELDS.NAME) {
                                    c.cssClass = "leftAlign name-field static-periods-width";
                                }
                            });
                            if (col.title === PSL_LINES) {
                                col.cssClass="fit-header-yoy"
                            }
                        }
                        else {
                            col.dontFilter = true;
                            col.headerSort= false;
                            col.cssClass = "leftAlign";
                            if(col.field && col.field === VECTOR_ANALYSIS.FIELDS.LEVEL) {
                                col.visible = false
                            }
                        }
                    });
                }
                _this.setState({
                    dataId: shortid.generate(),
                    columns: columns,
                    bridgeTableData: data.data,
                    costClassification: data.costClassification,
                    glCosts: data.glCosts,
                    client_costcenter: data.client_costcenter,
                });
            }
            this.setState({
                finishedLoading: true
            })
            if(_this.pageComponent){
                _this.pageComponent.expandedColumns =[];
            }
        };

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchBridgeTableData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]:  API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.bridge_report.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.bridge_report.requests_description.data,
            [FETCHAPI_PARAMS.vector]: this.props.vector,
            [FETCHAPI_PARAMS.periods]: "Current Periods: '"+ periods[0] +"', Previous Periods: '"+ periods[1] +"'",
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        this.fetchAPI(fetchOptions);
    }

    handleChangeBridgeFilter(e) {
    }

    handleChangeBridgeVector(e) {
    }

    generateReport() {
    }

    expandReport() {
    }

    componentDidMount() {
        $("#bridge-chart-div").addClass("bridge-chart-report-screen");
        if (this.props.fetchDataOnMount) {
            this.fetchBridgeData();
            if(!this.props.dontRenderTable){
                this.fetchBridgeTableData();
            }
        }
    }

    componentWillUnmount() {
        // Clean up the observer when the component unmounts
        if (this.resizeObserver && this.bridgeChartRef?.current) {
          this.resizeObserver.unobserve(this.bridgeChartRef?.current?.bridgeChartRef.current);
          this.resizeObserver = null;
        }
      }
    /**
     * function called on click of any bar in the barchart, sends request to fetch drilled data and displays the drill table by changing the state
     * @param {*} e 
     */

    drill=(e)=>{
        let _this = this;
        if(this.props.dontRenderTable){
            return;
        }
        if(this.state.event && this.state.event.item && this.state.event.item.columnGraphics) {
            $(this.state.event.item.columnGraphics.node.firstChild).attr('stroke-width',convertPxToViewport(1)); // removing highlight from previously selected bar
            $(this.state.event.item.columnGraphics.node.firstChild).attr('stroke','#BBBBBB');// removing highlight from previously selected bar
        }
        if (e.item && e.item.columnGraphics) {
            $(e.item.columnGraphics.node.firstChild).attr('stroke-width',convertPxToViewport(5));//highlighting selected bar
            $(e.item.columnGraphics.node.firstChild).attr('stroke','#0E5FFA');//highlighting selected bar
        } 
        let value = "";
        if(e.type === "clickItem"){
            value = e.value;
        } else {
            value = e.item.category;
            if(value.includes("Comparison")){
                value = value.replace("Comparison","α");
            } else if(value.includes("Selection")){
                value = value.replace("Selection","β");
            }
        }
        this.setState({
            showDrillTable:true,
            drillTitle:value,
            drilledItem: value,
            event:e,
            barLabel: value,
            finishedLoading: true
        },()=>{
            $(".vector-analysis-bridge-report").addClass("half-chart");
            $("#bridge_report_tables").addClass("half-screen-tables")
            $("#bridge_report_tables").removeClass("tables-full-screen")
            $("#bridge_report_tables").removeClass("height-unset")
            _this.fetchHigherLevelVectors();
            if ([NO_SALES, NEW_SALES, COMPARISON+_this.props.bridgeName, SELECTION+_this.props.bridgeName, "β "+_this.props.bridgeName, "α "+_this.props.bridgeName].includes(value)){ // if comparison or selection bridge or no sales or new sales 
                _this.props.fetchReportData(true, value);
            } else {
                _this.fetchBridgeData(true, value);
            }
            if(_this.pageComponent){
                _this.pageComponent.expandedColumns =[];
            }
        })
    }

    closeDrill = ()=> {
        let obj = this;
        if(this.state.event && this.state.event.item && this.state.event.item.columnGraphics) {
            $(this.state.event.item.columnGraphics.node.firstChild).attr('stroke-width',convertPxToViewport(1));// removing highlight from previously selected bar
            $(this.state.event.item.columnGraphics.node.firstChild).attr('stroke','#BBBBBB');// removing highlight from previously selected bar
        }
        this.setState({
            showDrillTable:false,
            event:undefined,
            groupedByVector: {value: NONE.value, label:NONE.label},
            isTableFullScreen:true
        },function(){
            $("#bridge_report_table").show();
            $(".vector-analysis-bridge-report").show();
            $(".vector-analysis-bridge-report").removeClass("half-chart");
            $("#bridge_report_tables").removeClass("half-screen-tables")
            $("#bridge_report_tables").removeClass("tables-full-screen")
            $("#bridge_report_tables").addClass("height-unset")
            obj.bridgeTableRef?.setColumns(obj.state.data, obj.state.columns); // rerender the columns when closing the drill

            if(obj.bridgeTableRef ){
                obj.bridgeTableRef.setState({
                    isTableExpanded: false
                },()=>{
                    obj.bridgeTableRef?.refreshData(); // on drilling then exit drill then closing drill lever pss table data doesn't show fix
                })
            } 
        });

        
    }

    getDownloadFormatterParams=()=> {
        var obj = this;
        let vector = this.props.displayVector.length>0 ? this.props.displayVector[0].label : this.props.vector;
        let mixVector = this.props.vectorOptions.length > 0 ? this.props.vectorOptions.filter(e=>e.value === obj.props.mixVector)[0]?.label : this.props.vector

        let params = {
            Report: "Bridge Report",
            User: obj.props.user.first_name + " " + obj.props.user.last_name,
            "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
            "Scenario":obj.props.scenarioObject["scenario_number"],
            "Vector":vector,
            "Mix Vector":mixVector,
            "Comparsion Type": obj.props.comparisonValue? findOptionByKey(this.comparisonOptions, obj.props.comparisonValue)?.label:"",
            "Bridge": obj.props.bridgeName,
            "Filter": (typeof obj.props.exportQueryFilter === "function" ? obj.props.exportQueryFilter() : obj.props.exportQueryFilter) || "None"
        }
        // if([YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(obj.props.comparisonValue)) {
            params["Start Period"] = getActualYear(obj.props.startPeriod) + getPeriodTextFromMonth(obj.props.startPeriod);
            params["End Period"] = getActualYear(obj.props.endPeriod) + getPeriodTextFromMonth(obj.props.endPeriod);
        // }
        // } else {
        //     params["Start Quarter"] = obj.props.startQuarter;
        //     params["End Quarter"] = obj.props.endQuarter;
        // }
        params = this.formatParams(params);
        return params;
    }

        getExportOpts() {
        var periods =this.props.computePriorPeriods();
        let vector = this.props.displayVector.length>0 ? this.props.displayVector[0].label : this.props.vector
        var exportOpts = {
            report: "Bridge Report Table",
            vector: vector,
            dataset: periods[0] + " - " + periods[1],
            user: this.props.user.first_name +" " + this.props.user.last_name,
            scenarioNumber: this.props.scenarioObject["scenario_number"],
            dataStartRow: 2,
            // skipColumn : 1,
            fileName:vector
        };
        return exportOpts;
    }

    

    exportDrillTableToExcel=()=> {
        let vector = this.props.displayVector.length > 0 ? this.props.displayVector[0].label : this.props.vector;
        let _this = this;
        let mixVector = this.props.vectorOptions.length > 0 ? this.props.vectorOptions.filter(e=>e.value === _this.props.mixVector)[0].label : this.props.vector
        var options = {
            mixVector: mixVector,
            vector: vector,
            drillTitle: this.state.drillTitle,
        };
        if(this.pageComponent){
            this.pageComponent.exportTableToExcel(options)
        }else{
            this.pageComponent2.exportTableToExcelDrill(options)
        }
    }

    // printReport() {
    //     var div = $("#chartdiv");
    //     html2canvas(div[0], {scale: '1.0'},{width:'500px'},{height:'200px'}).then(function(canvas) {
    //         $("#emptyDiv").append(canvas);
    //     });
    // }
    getDrillParams = () => {
        let _this = this;
        let vector = this.props.displayVector.length>0 ? this.props.displayVector[0].label : this.props.vector;
        let mixVector = this.props.vectorOptions.length > 0 ? this.props.vectorOptions.filter(e=>e.value === _this.props.mixVector)[0].label : this.props.vector
        if(_this.props.user && _this.props.scenarioObject && _this.props.comparisonValue && ((_this.props.startPeriod &&  _this.props.endPeriod) || (_this.props.startQuarter &&  _this.props.endQuarter))){
            let comparisonTypes = this.comparisonOptions;
            let comparison = comparisonTypes.filter(e=>e.value === _this.props.comparisonValue)[0].label;
            let params = {
                    "Report": "Bridge Drill",
                    "User": _this.props.user.first_name + " " + _this.props.user.last_name,
                    "Filter": _this.props.exportQueryFilter() || "None",
                    "Vector":vector,
                    "Bridge":_this.props.bridgeLabel,
                    "Mix Vector":mixVector,
                    "Comparison Type":comparison,
                    "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
                    "Scenario": _this.props.scenarioObject["scenario_number"],
                }
                // if([YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(_this.props.comparisonValue)) {
                
                    params["Start Period"] = getActualYear(_this.props.startPeriod) + getPeriodTextFromMonth(_this.props.startPeriod);
                    params["End Period"] = getActualYear(_this.props.endPeriod) + getPeriodTextFromMonth(_this.props.endPeriod);
                // } else {
                //     params["Start Quarter"] = _this.props.startQuarter;
                //     params["End Quarter"] = _this.props.endQuarter;
                // }
            params = this.formatParams(params);
            return params;
        }
    }

    formatParams = (params)=>{
        let data = []
        for(const property in params){
            let obj = {title:property,detail:params[property]}
            data.push(obj)
        }
        return data;
    }

    /**
     * Function that renders the legend based on the isEmpty param to show the empty legend or the data to the user
     * @param isEmpty
     * @returns {JSX.Element}
     */
    renderLegend = (isEmpty) => {
        if (isEmpty) {
            return (
                <Legend legendItems={this.emptyLegend}/>
            )
        } else {
            return (
                <Legend legendItems={this.props.getLegendItems}/>
            )
        }
    }

    /**
     * Empty Legend to show when the data is still loading
     * @returns {*[]}
     */
    emptyLegend = () => {
        let legendItems = [];

        let alphaLegend = VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL.concat(":  ");
        let betaLegend = VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL.concat(":  ");
        let deltaLegend = VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL.concat(":  ");

        legendItems.push(alphaLegend);
        legendItems.push(betaLegend);
        legendItems.push(deltaLegend);

        return legendItems;
    }

    /**
     * this function in called on maximizing full screen the drilled and PSL tables
     */
    maximizeTable = (drill) => {
        let _this = this;
        let isDrillTableFullScreen = drill? !this.state.isDrillTableFullScreen :this.state.isDrillTableFullScreen
        let isTableFullScreen = drill?this.state.isTableFullScreen : !this.state.isTableFullScreen;
        this.setState({
            hasFixedFooter1: isTableFullScreen && ((!isTableFullScreen && !drill) || (drill && !isDrillTableFullScreen)) ?  $("#bridge_drill_table .tabulator-tableHolder").hasClass("fixedFooter1") ? true : false : this.state.hasFixedFooter1,
            isTableFullScreen: drill?this.state.isTableFullScreen : !this.state.isTableFullScreen,
            isDrillTableFullScreen: drill? !this.state.isDrillTableFullScreen :this.state.isDrillTableFullScreen
        }, function () {
            if ((!_this.state.isTableFullScreen && !drill) || (drill && !_this.state.isDrillTableFullScreen)) {
      
                $("#bridge_drill_table").addClass("isTableFullScreen");
                $("#bridge_report_table").addClass("isTableFullScreen");

                $("#cards").addClass("uk-hidden");
                if (!this.state.isTableFullScreen) {
                    $("#bridge_drill_table .tabulator-tableHolder").addClass("fixedFooter");
                }else{
                    $("#bridge_drill_table .tabulator-tableHolder").removeClass("fixedFooter1");
                    $("#bridge_drill_table .tabulator-tableHolder").addClass("fixedFooter");
                }
            } else {
                $("#bridge-chart-div").addClass("chart-dimensions-when-drill-is-active");
                $("#bridge-chart-div").addClass("bridge-chart-report-screen");
   
                $("#bridge_drill_table").removeClass("isTableFullScreen");
                $("#bridge_report_table").removeClass("isTableFullScreen");
                $("#cards").removeClass("uk-hidden");
                $("#bridge_drill_table .tabulator-tableHolder").removeClass("fixedFooter");
                if (this.state.hasFixedFooter1) {
                    $("#bridge_drill_table .tabulator-tableHolder").addClass("fixedFooter1");
                }
            } 

            if(this.state.isTableFullScreen) {
              $("#bridge_report_tables").addClass("half-screen-tables");
              $("#bridge_report_tables").removeClass("tables-full-screen");
              $("#bridge_report_tables").removeClass("height-unset")
              $(".vector-analysis-bridge-report").show();
              $(".vector-analysis-bridge-report").addClass("half-chart");
            } else if(!this.state.isTableFullScreen) {
              $("#bridge_report_tables").removeClass("half-screen-tables");
              $("#bridge_report_tables").addClass("tables-full-screen");
              $("#bridge_report_tables").removeClass("height-unset")
              $(".vector-analysis-bridge-report").removeClass("half-chart");
              $(".vector-analysis-bridge-report").hide();
            }

            if(_this.periodRef){
                _this.periodRef.refreshSearch();
            }
            if(document.getElementById("filterText")){
                document.getElementById("filterText").focus();
            }
        })
    }
    
    warningModal = () => {
        return (
            <div className="uk-border-rounded">
                <h5 className="uk-text-large">{lang.incompatible_or_restricted}</h5>
            </div>
        )
    }

    dialogActions = () => {
        return (
            <Button 
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="uk-padding-small-right"
                onBtnClick={()=>this.setCloseWarning()}
            />
        )
    }

    setOpenWarning = () => {
        this.setState({
            openWarning:true
        })
    }

    setCloseWarning = () => {
        this.setState({
            openWarning:false
        })
    }

    setIsDrilling = (param) =>{
        this.props.setIsDrilling(param);
    }

    /**
     * when drilling inside the BridgeDrillTable component
     */
    fetchDataForDrill=(drillVector, key, groupBy, isBridgeReportDrill=true)=>{
        let value = this.state.drillTitle;
        if ([NO_SALES, NEW_SALES, COMPARISON+this.props.bridgeName, SELECTION+this.props.bridgeName, VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL +" " +this.props.bridgeName, VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL +" " +this.props.bridgeName].includes(value)){ // if comparison or selection bridge or no sales or new sales 
            if(this.props.isProfitOpp){
                this.props.fetchReportData(true, value, isBridgeReportDrill, drillVector, key);
            }else{
                this.props.fetchReportData(true, value, undefined, undefined, undefined,undefined,undefined, isBridgeReportDrill, drillVector, key);
            }
        } else {
            this.fetchBridgeData(true, value, isBridgeReportDrill, drillVector, key);
        }
        if(this.pageComponent){
            this.pageComponent.expandedColumns =[];
        }
    }


    addNewCard = (newRowData,drillVector)=>{
        let obj = this;
		let cardsData = [];
        let vectorObj = obj.props.vectorObj
		newRowData.tier = vectorObj.label;
		cardsData.push({data:[newRowData],columns: obj.getUpdatedColumns(newRowData.tier, undefined, undefined, true),
			 columnsViews: [],
			 profile: obj.props.manageColumnsProfile });
        obj.setState({
            cardsData:cardsData,
            drillVector:drillVector,
            drillTier:newRowData.tier,
            isDrillTableFullScreen: true,
            key: newRowData.key
		},obj.fetchDataForDrill(drillVector,newRowData.key));
	}



    getUpdatedColumns(vectorName="", isDrill=true, columns= this.props.drill_data.columns, useDrillColumns) {
		let tempColumns = useDrillColumns ? this.state.drillColumns ? copyObjectValues(this.state.drillColumns) :  copyObjectValues(columns): copyObjectValues(columns);
		// let quadrantColIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.QUADRANT_TIER);	//get index of quadrant column
		// quadrantColIndex = quadrantColIndex > -1 ? quadrantColIndex : findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.QUADRANT);	
		// let nameIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.NAME);	
		// let numberIndex = findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.NUMBER);	
		// let quadrantIndex =  findIndexOfValue(tempColumns, "field", PSL_RETURN_NAMES.QUADRANT);	
		// if(quadrantColIndex > -1 && nameIndex === -1 && numberIndex === - 1) {
		// 	if(isDrill) {
		// 		if(quadrantColIndex > -1) {
		// 			let suffix = "";
		// 			if (quadrantIndex === -1) {
		// 				suffix = " QT";
		// 			} else{
		// 				suffix = " Q";
		// 			}
		// 			tempColumns[quadrantColIndex].title = tempColumns[quadrantColIndex].title.replace(tempColumns[quadrantColIndex].title, vectorName +suffix).trim();
		// 		}
		// 	}
		// } else if(isDrill) {
		// 	if(quadrantIndex > -1){
		// 		tempColumns[quadrantIndex].title = tempColumns[quadrantIndex].title.replace(tempColumns[quadrantIndex].title, vectorName +" Q").trim();
		// 	}

		// 	if(quadrantColIndex > -1) {
		// 		tempColumns[quadrantColIndex].title = tempColumns[quadrantColIndex].title.replace(tempColumns[quadrantColIndex].title, vectorName +" QT").trim();
		// 	}

		// 	if(nameIndex > -1) {
		// 		tempColumns[nameIndex].title = tempColumns[nameIndex].title.replace(tempColumns[nameIndex].title,vectorName).trim();
		// 	}
			
		// 	if(numberIndex > -1) {
		// 		tempColumns[numberIndex].title = tempColumns[numberIndex].title.replace(tempColumns[numberIndex].title,vectorName +" #").trim();
		// 	}
		// }
		return tempColumns;
	}

    setCardsData=(cardsData)=> {
        let obj = this;
		this.setState({
			cardsData:cardsData
		},obj.bridgeCardRef[0].setState({
            isTableExpanded:true
        }))
	}


    renderCards = () => {
		let cardsData = copyObjectValues(this.state.cardsData);
		let cards = []
		
		for(let e in cardsData){
			let cardDetails = cardsData[e]["data"][0];
			cards.push(<Card key={"card_"+e} ref={el => this.bridgeCardRef[e] = el} index = {Number(e)} card={cardsData[e]} cardsData={cardsData} parentTableReport="drill-table-top" columnFiltering={false} headerSort={false}
			user={this.props.user} profitFormat={this.props.reportTitle} periods={this.props.vectorOptions} 
			scenario_id={this.props.scenario}  scenarioNumber={this.props.scenarioObject["scenario_number"]} 
            dataset={this.props.dataset} 
			isDashBoards={false} isPaginated={false} tier={this.props.vectorOptions && this.props.vectorOptions.filter(e=>e.value ===this.props.mixVector).length>0? this.props.vectorOptions.filter(e=>e.value ===this.props.mixVector)[0].label:""} name={cardDetails["name"]} qt={cardDetails["quadranttier"] ? cardDetails["quadranttier"] :cardDetails["quadrant"]} 
            setCardsData={this.setCardsData} addNewCard={this.addNewCard} setIsDrilling={this.setIsDrilling} 
            isDrilling={this.props.isDrilling} records_limit= {this.props.limit} isDrillBridgeReport={true}
            columns={this.state.columns} bridge={this.props.bridge}
            comparisonValue={this.props.comparisonValue} 
            scenarioObject={this.props.scenarioObject}
            mixVector={this.props.mixVector} vector={this.props.vector} vectorObj={this.props.vectorObj}
            startPeriod={this.props.startPeriod}
            endPeriod={this.props.endPeriod} startQuarter={this.props.startQuarter}
            endQuarter={this.props.endQuarter}
            drill_data={this.props.drill_data}
            exportQueryFilter={this.props.exportQueryFilter}
            chartData={[]} bridgeName={this.props.bridgeName} barLabel={this.state.barLabel}
            getLegendItems={this.props.getLegendItems} getSymbolColor={this.props.getSymbolColor}
            getSymbolsTooltipFormatter={this.props.getSymbolsTooltipFormatter}
            getSymbolsFormatter={this.props.getSymbolsFormatter}
            getToolTipMessage={this.props.getToolTipMessage}
            event={this.state.event} vectorOptions={this.props.vectorOptions} isTableFullScreen={this.state.isTableFullScreen}
            />);
		}
		return cards;
		
	}
    
    
    handleElementChange=(e)=> {
        if ([null, undefined].includes(e)) {
            return;
        }
        let _this = this;
        let value = e.value;
        _this.setState({
            groupedByVector: {value: value},
            isGroupingByVector: value && value !== NONE.value
        },function(){
            _this.fetchDataForDrill(_this.state.drillVector, _this.state.key, value, false);
        });
    }

    render() {
      let _this = this;
      let dontRenderTable = this.props.dontRenderTable; // if we are coming from the widget, we only have to render the chart, no need to get downloadFormatterParams, exportOpts and params
      var downloadFormatterParams = !dontRenderTable ? this.getDownloadFormatterParams() : "";
      var exportOpts = !dontRenderTable ? this.getExportOpts() : "";
      let desc =
        this.state.chartData &&
        this.state.chartData.filter((e) => e.name === _this.state.drillTitle)[0] &&
        this.state.chartData.filter((e) => e.name === _this.state.drillTitle)[0].description;
      let params = !dontRenderTable ? (this.state.showDrillTable ? this.getDrillParams() : this.getDownloadFormatterParams()) : "";
      let legend = this.state.finishedLoading ? this.renderLegend(false) : this.renderLegend(true);
      let vectorDesc = this.props.vectorOptions && this.props.vectorOptions.filter((e) => e.label === this.state.drillVector).length > 0 ? this.props.vectorOptions.filter((e) => e.label === this.state.drillVector)[0].vector_description : "";
      const groupByOptions = ({ label, isDisabled, isGroupTitle }) => (
        <div className={(isDisabled && isGroupTitle ? "option-group-header" : "") + " option-label-padding uk-flex-between uk-display-flex"}>
          {label}{" "}
          {isDisabled && !isGroupTitle ? (
            <i className="fal fa-info-circle" style={{ float: "right", color: "black", marginTop: convertPxToViewport(5) }} />
          ) : (
            ""
          )}
        </div>
      );
      if (desc !== "") {
        if (typeof desc !== "string") {
          desc = JSON.stringify(desc);
        }
      } else {
        switch (_this.state.drillTitle) {
          case lang.bridge_report.title.price: {
            desc = lang.bridge_report.title.price.toLowerCase() + ".png";
            break;
          }
          case lang.bridge_report.title.cogs: {
            desc = lang.bridge_report.title.cogs.toLowerCase() + ".png";
            break;
          }
          case lang.bridge_report.title.volume: {
            desc = lang.bridge_report.title.volume.toLowerCase() + ".png";
            break;
          }
          case lang.bridge_report.title.mix: {
            desc = lang.bridge_report.title.mix.toLowerCase() + ".png";
            break;
          }
          case lang.bridge_report.title.returns: {
            desc = lang.bridge_report.description.returns_desc;
            break;
          }
          case lang.bridge_report.title.new_sales: {
            desc = lang.bridge_report.description.new_sales_desc.replace("[mix vector]", vectorSpacing(this.props.mixVector));
            break;
          }
          case lang.bridge_report.title.no_sales: {
            desc = lang.bridge_report.description.no_sales_desc.replace("[mix vector]", vectorSpacing(this.props.mixVector));
            break;
          }
          default:
            break;
        }
      }
      if (vectorDesc !== "") {
        if (typeof vectorDesc !== "string") {
          vectorDesc = JSON.stringify(vectorDesc);
        }
      }
      let cards = this.renderCards();
  
      return (
        <>
          <Modal
            id={"bridge-report-warning-dialog"}
            openDialog={this.state.openWarning}
            bodyContent={this.warningModal}
            dialogActions={this.dialogActions}
            closeClick={this.setCloseWarning}
            size={DIALOG_SIZE.MEDIUM}
          />
          {this.props.isDrilling ? (  // when drilling from the table and not from chart
            <div id={"drilling-container"}>
              <div className="uk-flex uk-flex-right uk-margin-xsmall-bottom uk-flex-right uk-margin-medium-right">
                <Button
                  id="Export_Drill_Table"
                  label={lang.header.titles.xls}
                  title={lang.header.tooltips.xls}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => this.exportDrillTableToExcel()}
                  leftIcon={<i className="fal fa-file-excel fa-lg" aria-hidden="true" />}
                  style={{ marginTop: "0.1vw" }}
                />
              </div>
              <div className="uk-margin-small-left uk-margin-small-right">
                <div className="uk-margin-small-left uk-margin-small-right" id="cards">
                  {cards}
                </div>
              </div>
              <div
                className={this.state.isDrillTableFullScreen ? "uk-margin-medium-top" : ""}
                style={!this.state.isDrillTableFullScreen ? { height: "94.5%" } : { height: "64%" }}
              >
                <div className="uk-border drill-header-container uk-flex uk-flex-between ">
                  <div className="uk-flex uk-flex-middle">
                    <div className="uk-button-icon transparent-bg uk-margin-xsmall-left" onClick={() => this.maximizeTable(true)}>
                      <i class={"fa-lg far fa-" + (this.state.isDrillTableFullScreen ? "expand" : "compress")}></i>
                    </div>
                    <span className="uk-flex uk-padding-xxsmall-left">
                      <b className="fs-14">
                        {this.props.vectorOptions && this.props.vectorOptions.filter((e) => e.value === this.state.drillVector).length > 0
                          ? this.props.vectorOptions.filter((e) => e.value === this.state.drillVector)[0].label
                          : ""}
                      </b>
                      {vectorDesc ? (
                        <i
                          className="fal fa-info-circle uk-display-flex uk-flex-middle uk-margin-xsmall-left uk-cursor-pointer"
                          uk-tooltip={vectorDesc}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <BridgeDrillTable
                  ref={(el) => (this.pageComponent2 = el)}
                  id={"table-list"}
                  columns={this.state.columns}
                  bridge={this.props.bridge}
                  comparisonValue={this.props.comparisonValue}
                  user={this.props.user}
                  scenarioObject={this.props.scenarioObject}
                  mixVector={this.props.mixVector}
                  vector={this.props.vector}
                  vectorObj={this.props.vectorObj}
                  customStartDate={this.props.startPeriod}
                  customEndDate={this.props.endPeriod}
                  startQuarter={this.props.startQuarter}
                  endQuarter={this.props.endQuarter}
                  profitFormat={this.props.reportTitle}
                  exportQueryFilter={this.props.exportQueryFilter}
                  data={{ data: [], columns: this.props.drill_data.columns.filter((e) => e.title !== "Drill") }}
                  chartData={[]}
                  bridgeName={this.props.bridgeName}
                  barLabel={this.state.barLabel}
                  getLegendItems={this.props.getLegendItems}
                  getSymbolColor={this.props.getSymbolColor}
                  getSymbolsTooltipFormatter={this.props.getSymbolsTooltipFormatter}
                  getSymbolsFormatter={this.props.getSymbolsFormatter}
                  getToolTipMessage={this.props.getToolTipMessage}
                  event={this.state.event}
                  allowDrill={true}
                  vectorOptions={this.props.vectorOptions}
                  parentId={"To_Scroll_List"}
                  isTableFullScreen={this.state.isTableFullScreen}
                  setIsDrilling={this.setIsDrilling}
                  addNewCard={this.addNewCard}
                  DontAddDrill={true}
                  computePriorPeriods={this.props.computePriorPeriods}
                  mixVectorTabulator={this.bridgeCardRef[0] ? this.bridgeCardRef[0].ref3 : ""}
                  drillVector={this.state.drillVector}
                  getDownloadFormatterParams={this.getDownloadFormatterParams}
                  isDrilling={this.props.isDrilling}
                  isDrillTableFullScreen={this.state.isDrillTableFullScreen}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={(this.props.fromWidget ? "bridge-dashboards " : "") + "vector-analysis-bridge-report" + (this.props.isDrilling ? " uk-hidden" : "") + (this.state.showDrillTable ? " half-chart" : "")}>
                {this.state.isTableFullScreen ? (
                  <>
                    <BridgeChart
                      ref={this.bridgeChartRef}
                      chartData={this.state.chartData}
                      chartUnit={this.state.chartUnit}
                      mixVector={this.props.mixVector}
                      bridgeLabel={this.props.bridgeLabel}
                      selectedNames={this.props.selectedNames}
                      drill={this.drill}
                      event={this.state.event}
                      fromWidget={dontRenderTable}
                      unique_id={"bridge-chart-div" + (replaceSpecialChars(this.props.unique_id, "_") || "")}
                     
                    />
                    {legend}
                  </>
                ) : null}
              </div>
              {!dontRenderTable ? ( //if we are coming from the widget don't show tables
                <div id="bridge_report_tables" className={!this.state.isTableFullScreen ? "tables-full-screen" : this.bridgeTableRef?.state.isTableExpanded || this.state.showDrillTable ? "half-screen-tables" : "height-unset"} >
                  <div id="drill_tabulator" className={this.state.showDrillTable ? "has_grouping" : " uk-hidden"}>
                    <div className="uk-border drill-header-container uk-flex uk-flex-between">
                      <div className="uk-flex uk-flex-middle">
                        <Button
                          variant={BUTTON_VARIANT.TERTIARY}
                          size={SIZES.ICON}
                          type={BUTTON_TYPE.DEFAULT}
                          className="uk-button-icon close-button uk-float-unset uk-margin-remove"
                          data-dismiss="modal"
                          leftIcon={<i className="fal fa-times" />}
                          aria-label="Close"
                          onBtnClick={() => {
                            this.closeDrill();
                          }}
                        />
                        <div className="uk-button-icon transparent-bg uk-margin-xsmall-left" onClick={() => this.maximizeTable()}>
                          <i className={"fa-lg far fa-" + (this.state.isTableFullScreen ? "expand" : "compress")}></i>
                        </div>
                        <span className="uk-flex uk-padding-xxsmall-left">
                          <b className="fs-14">{this.state.drillTitle}</b>
                          {desc ? (
                            ![
                              lang.bridge_report.title.price,
                              lang.bridge_report.title.cogs,
                              lang.bridge_report.title.volume,
                              lang.bridge_report.title.mix,
                            ].includes(_this.state.drillTitle) ? (
                              <i
                                className="fal fa-info-circle uk-display-flex uk-flex-middle uk-margin-xsmall-left uk-cursor-pointer"
                                uk-tooltip={desc}
                              ></i>
                            ) : (
                              <div uk-lightbox="animation: fade;">
                                <a
                                  className="blue-info-circle fa-lg blueText text-remove-decoration fas fa-info-circle uk-padding-xxsmall-left"
                                  title={lang.bridge_report.description.price_cogs_volume_mix}
                                  href={"../../images/lightbox/bridge_report/" + desc}
                                ></a>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                        {this.props.userAllowedMenuLinks && getSectionExists(this.props.userAllowedMenuLinks, BRIDGE_REPORT_GROUP_BY) ? (
                          <div className="uk-flex uk-flex-middle">
                            <span className="uk-margin-left fs-14 disabled">{lang.bridge_report.view}</span>
                            <b className="uk-margin-small-left black fs-14">
                              {this.props.vectorOptions && this.props.vectorOptions.filter((e) => e.value === this.props.mixVector).length > 0
                                ? this.props.vectorOptions.filter((e) => e.value === this.props.mixVector)[0].label
                                : ""}
                            </b>
                            <span className="disabled fs-14 uk-margin-small-left uk-margin-small-right">{lang.bridge_report.grouped_by}</span>
                            <DropDown
                              id="select-set"
                              filterOption={(option, searchText) => {
                                return option.label.toLowerCase().includes(searchText.toLowerCase());
                              }}
                              className="input-width-200 uk-cursor-pointer input__dropdown"
                              name={HEADER_ELEMENT.VECTOR}
                              value={findOptionByKey(this.state.vectorsGroupBy, this.state.groupedByVector)}
                              onChange={(e) => this.handleElementChange(e)}
                              options={this.state.vectorsGroupBy}
                              formatOptionLabel={groupByOptions}
                              type={DROPDOWN_TYPE.INPUT}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="uk-flex">
                        <Button
                          id="Export_Drill_Table"
                          label={lang.header.titles.xls}
                          title={lang.header.tooltips.xls}
                          variant={BUTTON_VARIANT.SECONDARY}
                          size={SIZES.DEFAULT}
                          type={BUTTON_TYPE.DEFAULT}
                          onBtnClick={() => this.exportDrillTableToExcel()}
                          leftIcon={<i className="fal fa-file-excel fa-lg" aria-hidden="true" />}
                        />
                      </div>
                    </div>
                    {this.state.showDrillTable ? (
                      <BridgeDrillTable
                        ref={(el) => (this.pageComponent = el)}
                        id={"table-list"}
                        columns={this.state.columns}
                        bridge={this.props.bridge}
                        comparisonValue={this.props.comparisonValue}
                        user={this.props.user}
                        scenarioObject={this.props.scenarioObject}
                        mixVector={this.props.mixVector}
                        vector={this.props.vector}
                        vectorObj={this.props.vectorObj}
                        customStartDate={this.props.startPeriod}
                        customEndDate={this.props.endPeriod}
                        startQuarter={this.props.startQuarter}
                        endQuarter={this.props.endQuarter}
                        profitFormat={this.props.reportTitle}
                        exportQueryFilter={this.props.exportQueryFilter}
                        data={this.props.drill_data}
                        groupBy={this.state.groupedByVector}
                        chartData={this.state.chartData}
                        bridgeName={this.props.bridgeName}
                        barLabel={this.state.barLabel}
                        getLegendItems={this.props.getLegendItems}
                        getSymbolColor={this.props.getSymbolColor}
                        getSymbolsTooltipFormatter={this.props.getSymbolsTooltipFormatter}
                        getSymbolsFormatter={this.props.getSymbolsFormatter}
                        getToolTipMessage={this.props.getToolTipMessage}
                        event={this.state.event}
                        allowDrill={true}
                        vectorOptions={this.props.vectorOptions}
                        parentId={"To_Scroll_List"}
                        isTableFullScreen={this.state.isTableFullScreen}
                        setIsDrilling={this.setIsDrilling}
                        addNewCard={this.addNewCard}
                        computePriorPeriods={this.props.computePriorPeriods}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={{height: "100%"}} className={this.state.showDrillTable ? "uk-hidden" : "noprint"}>
                    <ProfitStackTabulator
                      ref={(el) => (this.bridgeTableRef = el)}
                      exportOpts={exportOpts}
                      downloadFormatterParams={downloadFormatterParams}
                      isBridge={true}
                      component={PROFIT_STACK}
                      scenario={this.props.scenario}
                      getToolTipMessage={this.props.getToolTipMessage}
                      columns={this.state.columns}
                      data={this.state.bridgeTableData}
                      dataId={this.state.dataId}
                      periodsList={this.props.periodsList}
                      getLegendItems={this.props.getLegendItems}
                      tableId={"bridge_report_table"}
                      getSymbolColor={this.props.getSymbolColor}
                      getSymbolsTooltipFormatter={this.props.getSymbolsTooltipFormatter}
                      getSymbolsFormatter={this.props.getSymbolsFormatter}
                      comparisonValue={this.props.comparisonValue}
                      costClassification={this.state.costClassification}
                      glCosts={this.state.glCosts}
                      client_costcenter={this.state.client_costcenter}
                      vectorOptions={this.props.vectorOptions}
                      maximizeTable={this.maximizeTable}
                      isTableFullScreen={this.state.isTableFullScreen}
                      filterFinal={this.props.filter}
                    />
                  </div>
                  <div className="uk-hidden">
                    <ExcelDetailsTable ref={(el) => (this.excelDetails = el)} params={params} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </>
      );
    }
}

export default BridgeReport;